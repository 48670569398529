<template>
  <div class="homePageWidth">
    <TrainingDialogThree ref="openTrainingDialogThree"></TrainingDialogThree>
    <template v-if="userIsLoggedIn == true">
      <v-row>
        <v-col cols="12" class="my-15"></v-col>
        <v-col cols="12" class="my-15">
          <h1 class="text-center">{{ $t("extrakeys.courses") }}</h1>
          <a href="/mapping"> <-- {{ $t("extrakeys.mappingTool") }}</a>
        </v-col>
        <v-col cols="12" class="mt-5 mb-15">
          <p>{{ $t("extrakeys.belowAreAllCourses") }}</p>
        </v-col>
        <!-- <pre>{{ courseCollections }}</pre> -->
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          v-for="(course, courseIndex) in sortedCourses"
          :key="courseIndex"
          class="pa-5"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              class="pa-5"
              height="100%"
              @click="$refs.openTrainingDialogThree.openDialog(course)"
            >
              <!-- <pre>{{ course.language }}</pre> -->
              <!-- <pre>{{ course.id }}</pre> -->
              <!-- <p class="text-center title">{{ course.id }}</p> -->
              <p class="text-center title">{{ course.name }}</p>
              <v-img
                :src="course.background_image"
                class="mx-auto"
                height="auto"
                width="200px"
                contain
                :alt="course.name"
              ></v-img>
              <p>{{ course.description }}</p>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" class="my-15"></v-col>
        <v-col cols="12" class="my-15">
          <h1>{{ $t("extrakeys.pleaseLogIn") }}</h1>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import TrainingDialogThree from "@/components/TrainingModule/TrainingModuleDialogThree.vue";
export default {
  components: {
    TrainingDialogThree,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      courseCollections: [],
      userData: [],
      userIsLoggedIn: false,
      customCourseOrder: [
        100, 97, 99, 94, 101, 102, 103, 104, 105, 106, 107, 108, 109, 127,
      ],
      // 1. Spotting Opportunities - 100, 220
      // 2. After the storm comes the Idea - 97, 219
      // 3. Mobilising Resources - 99, 222
      // 4. How to write a business plan - 94, 218
      // 5. Grow sustainably step by step - 101, 235
      // 6. If there is no plan it`s no fun - 102, 228
      // 7. Together we take longer but we get further - 103, 223
      // 8. Learning through experience - 104, 224
      // 9. Teamwork makes the dreamwork - 105, 221
      // 10. Communication plan - 106, 225
      // 11. Sucess doesn`t come to you, you go to it.  - 107, 227
      // 12. Digital transformation - 108, 226
      // 13. Circualar business - 109, 229
      // 14. Social busienss - 127, 230

      /*
        
      */
    };
  },
  mounted() {
    this.checkIfLoggedIn();
    this.getAllCourses();
  },
  methods: {
    getAllCourses() {
      this.courseCollections = [];
      this.$http
        .get(
          `https://app.followup.prios.no/api/courses/collection?tenant_id=106`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.courseCollections = response.data;
        });
    },

    updateCustomOrder(newOrder) {
      this.customCourseOrder = newOrder;
    },

    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.userData = userData;
      if (userData) {
        this.userIsLoggedIn = true;
      } else {
        this.userIsLoggedIn = false;
      }
    },
  },
  computed: {
    sortedCourses() {
      // Retrieve the language from local storage
      const storedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if not found

      // Define a mapping between i18n language codes and courseCollection language names
      const languageMapping = {
        en: "English",
        nb: "Norwegian",
        el: "Greek", // 173 miss content
        de: "German",
        es: "Spanish",
        pl: "Polish",
        // Add more mappings as needed
      };

      /* Define custom course orders for each language
       - Modules (Giving ID in order of language above).
      // 1. Spotting Opportunities - 100, 220, 178, 206, 193, 238
      // 2. After the storm comes the Idea - 97, 219, 166, 211, 236, 239
      // 3. Mobilising Resources - 99, 222, 169, 217, 237, 240
      // 4. How to write a business plan - 94, 218, 177, 216, 234, 241
      // 5. Grow sustainably step by step - 101, 235, 172, 231, 261, 242
      // 6. If there is no plan it`s no fun - 102, 228, 174, 232, 260, 243
      // 7. Together we take longer but we get further - 103, 223, 176, 233, 259, 244
      // 8. Learning through experience - 104, 224, 165, 202, 258, 245
      // 9. Teamwork makes the dreamwork - 105, 221, 167, 203, 257, 246
      // 10. Communication plan - 106, 225, 168, 204, 256, 247
      // 11. Sucess doesn`t come to you, you go to it.  - 107, 227, 170, 212, 255, 248
      // 12. Digital transformation - 108, 226, 171, 213, 254, 249
      // 13. Circualar business - 109, 229, 173, 214, 252, 250
      // 14. Social busienss - 127, 230, 175, 215, 253, 251
     */
      const customCourseOrders = {
        // English
        en: [100, 97, 99, 94, 101, 102, 103, 104, 105, 106, 107, 108, 109, 127],
        // Norwegian
        nb: [
          220, 219, 222, 218, 235, 228, 223, 224, 221, 225, 227, 226, 229, 230,
        ],
        // Greek
        el: [
          178, 166, 169, 177, 172, 174, 176, 165, 167, 168, 170, 171, 173, 175,
        ],
        // Spanish
        es: [
          206, 211, 217, 216, 231, 232, 233, 202, 203, 204, 212, 213, 214, 215,
        ],
        // German
        de: [
          193, 236, 237, 234, 261, 260, 259, 258, 257, 256, 255, 254, 252, 253,
        ],
        // Polish
        pl: [
          238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251,
        ],

        // Add custom course orders for other languages as needed
      };

      // Filter the courseCollections based on language
      const filteredCourses = this.courseCollections.filter((course) => {
        const courseLanguage = languageMapping[storedLanguage];
        return course.language === courseLanguage;
      });

      // If no courses in the specified language are found, show English courses
      const coursesToShow =
        filteredCourses.length > 0
          ? filteredCourses
          : this.courseCollections.filter(
              (course) => course.language === "English"
            );

      // Sort the courses based on the custom order of IDs for the current language
      const customCourseOrder = customCourseOrders[storedLanguage] || [];
      const sortedCourses = coursesToShow.sort((a, b) => {
        const indexOfA = customCourseOrder.indexOf(a.id);
        const indexOfB = customCourseOrder.indexOf(b.id);

        // If both IDs are in the custom order, compare their positions
        if (indexOfA !== -1 && indexOfB !== -1) {
          return indexOfA - indexOfB;
        }

        // If only one of the IDs is in the custom order, prioritize it
        if (indexOfA !== -1) {
          return -1;
        }
        if (indexOfB !== -1) {
          return 1;
        }

        // If neither ID is in the custom order, maintain their original order
        return 0;
      });

      return sortedCourses;
    },
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}
</style>
