<template>
  <div class="homePageWidth">
    <!-- Home Page Content -->
    <v-row>
      <v-col cols="12" class="mt-5"></v-col>

      <!-- Intro Image -->
      <v-col cols="12" class="pb-0">
        <v-card class="mt-15 pb-3 introImage">
          <v-img src="../assets/images/HomepageRadiant.png">
            <p class="introTitle makeTextPop">{{ $t("home.intro title") }}</p>
            <p class="introDescription makeTextPop">
              {{ $t("home.intro description") }}
            </p>
          </v-img>
        </v-card>
      </v-col>

      <!-- 3 Cards in center of cards -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
            v-for="(pageCard, pageCardIndex) in pageCards"
            :key="pageCardIndex"
          >
            <v-card
              class="pageCardPositioning"
              :to="pageCard.link"
              style="position: relative"
            >
              <v-divider
                v-if="pageCardIndex == 0"
                class="pageCardDividerBlue"
              ></v-divider>
              <v-divider
                v-if="pageCardIndex == 1"
                class="pageCardDividerGreen"
              ></v-divider>
              <v-divider
                v-if="pageCardIndex == 2"
                class="pageCardDividerRed"
              ></v-divider>
              <v-row>
                <v-col cols="10" style="height: 150px">
                  <p class="pt-2 pageCardTitle">{{ pageCard.name }}</p>
                </v-col>
                <v-col cols="2">
                  <v-icon large class="pt-2 pageCardArrow"
                    >mdi-arrow-right</v-icon
                  >
                </v-col>
              </v-row>
              <p class="pageCardDescription" style="height: 50px">
                {{ pageCard.description }}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Info Text -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12"></v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <p class="shortInfoTitle">{{ $t("home.info title") }}</p>
            <p class="shortInfoDescription">
              {{ $t("home.info description") }}
            </p>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <NewsMainPage></NewsMainPage>
      </v-col>
      <!-- See all news button - go to page -->
      <v-col cols="12" align="center" class="mt-2">
        <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news">
          {{ $t("home.news see all news button") }}
        </v-btn>
      </v-col>

      <!-- To keep the structure -->
      <v-col cols="12"></v-col>

      <!-- RSS -->
      <!-- <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pt-0">
        <RssForHome></RssForHome>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
// import RssForHome from "@/components/rss/rssForHome.vue";
import NewsMainPage from "@/components/News/NewsMainPage.vue";
export default {
  name: "Home",
  components: {
    // RssForHome,
    NewsMainPage,
  },
  data() {
    return {
      colorArr: ["#004AAD", "#000000", "#F59A3D", "#004AAD"],
      // In Page Pages Cards
      pageCards: [
        {
          name: this.$t("home.mapping button title"),
          color: "",
          link: "/mapping",
          description: this.$t("home.mapping button description"),
        },
        {
          name: this.$t("home.resources button title"),
          color: "",
          link: "/resources",
          description: this.$t("home.resources button description"),
        },
        {
          name: this.$t("home.about button title"),
          color: "",
          link: "/about",
          description: this.$t("home.about button description"),
        },
        // {
        //   name: this.$t("home.partners button title"),
        //   color: "",
        //   link: "/partners",
        //   description: this.$t("home.partners button description"),
        // },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

/* Intro Classes for box and text at the top */
.introImage {
  height: 650px;
  /* background-color:rgb(213, 211, 211); */
}
.introTitle {
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  margin-left: 15%;
  margin-top: 10%;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  font-style: normal;
}
.introDescription {
  font-size: 28px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  margin-left: 15%;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  font-style: normal;
}
/* Styling the 3 cards close to the intro top */
.pageCardPositioning {
  position: relative;
  top: -5em;
  right: 50;
  padding: 10px;
}
.pageCardDividerBlue {
  background-color: #004aad;
  padding: 2px;
}
.pageCardDividerGreen {
  background-color: #000000;
  padding: 2px;
}
.pageCardDividerRed {
  background-color: #f59a3d;
  padding: 2px;
}
.pageCardTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #434343;
  letter-spacing: 0;
}
.pageCardDescription {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  font-size: 14px;
  color: #6a6a6a;
  letter-spacing: 0;
}

.pageCardArrow {
  color: #205072;
  opacity: 1;
}

/* Short Info Text */
.shortInfoTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
}

.shortInfoDescription {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  text-align: center;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
}

/* See All News / RSS Button */
.seeAllButtonText {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #205072;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  text-transform: initial;
}
.seeAllButtonBorder {
  border: 3px solid #205072;
  font-family: "Lato", sans-serif;
  opacity: 1;
  border-radius: 52px;
}
.seeAllButtonBorder:after {
  border: 3px solid #d6d2d24d;
  content: "";
  position: absolute;
  top: -9px;
  right: -9px;
  bottom: -9px;
  left: -9px;
  border-radius: 4em;
  box-shadow: 0px 2px 12px #00000033;
}

.makeTextPop {
  text-shadow: 1px 1px white, -1px -1px white, -1px 1px white, 1px -1px white;
}
</style>
