<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" class="mt-12"></v-col>
      <v-col cols="12">
        <!-- userIsLoggedIn: {{userIsLoggedIn}}
        <pre>{{userData}}</pre>
        <p>Name:  {{userDetails.nameOfUser}}</p>

        <pre>{{userDetails}}</pre> -->

        <!-- Mapping tools frontpage - Template 1 -->
        <template v-if="mappingTemplate == 1">
          <h1 class="text-center">{{ $t("extrakeys.mappingTool") }}</h1>
          <v-col cols="12">
            <v-divider color="#ffffff"></v-divider>
            <v-divider color="#ffffff"></v-divider>
            <v-divider color="#ffffff"></v-divider>
            <v-divider color="#ffffff"></v-divider>
            <v-divider color="#ffffff"></v-divider>
          </v-col>
          <p class="text-center">
            {{ $t("extrakeys.ifFieldsGreyed") }}
          </p>
          <p class="text-center">
            {{ $t("extrakeys.chooseLanguageSelfAssessment") }}
          </p>
          <!-- Assessment Button - activate template 2-->
          <v-row class="ma-0 pa-0 mb-15">
            <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-btn v-if="userIsLoggedIn == true" block to="/selfassessment">{{
                $t("extrakeys.selfAssessmentTest")
              }}</v-btn>
              <v-btn v-else block disabled>{{
                $t("extrakeys.selfAssessmentTest")
              }}</v-btn>
            </v-col>

            <v-col cols="12" class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-card class="pa-5">
                    <v-img
                      src="@/assets/images/aboutPageImage.png"
                      width="auto"
                      height="auto"
                      cover
                    />
                    <v-card-title>
                      <p>{{ $t("extrakeys.allCourses") }}</p>
                    </v-card-title>
                    <v-card-text style="height: 100px">
                      <p class="pa-3">
                        {{ $t("extrakeys.fullAccessToAllCourses") }}
                      </p>
                    </v-card-text>
                    <v-btn v-if="userIsLoggedIn == true" to="/courses">{{
                      $t("extrakeys.seeAllCourses")
                    }}</v-btn>
                    <v-btn v-else disabled>{{
                      $t("extrakeys.seeAllCourses")
                    }}</v-btn>
                  </v-card>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-card class="pa-5">
                    <v-img
                      src="@/assets/images/aboutPageImage.png"
                      width="auto"
                      height="auto"
                      cover
                    />
                    <v-card-title>
                      <p>{{ $t("extrakeys.recommendedCourses") }}</p>
                    </v-card-title>
                    <v-card-text style="height: 100px">
                      <p class="pa-3">
                        {{ $t("extrakeys.recomendedCourseDescription") }}
                      </p>
                    </v-card-text>
                    <!-- Description on the card -->
                    <!-- <v-btn v-if="userIsLoggedIn == true" @click="changingTemplate(4)">Recommended things</v-btn> -->
                    <!-- <v-btn v-if="userIsLoggedIn == true" to="/recommended">Recommended things</v-btn> -->
                    <v-btn v-if="userIsLoggedIn == true" to="/recommended">{{
                      $t("extrakeys.seeYourRecommendedCourses")
                    }}</v-btn>
                    <v-btn v-else disabled>{{
                      $t("extrakeys.seeYourRecommendedCourses")
                    }}</v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>

        <!-- Question Stepper - Template 2 -->
        <template v-if="mappingTemplate == 2">
          <h1 class="text-center mb-15 mt-5">
            {{ $t("extrakeys.selfAssessmentTest") }}
          </h1>
          <v-btn @click="changingTemplate(1)" class="mb-15 mt-5">{{
            $t("extrakeys.back")
          }}</v-btn>
          <!-- <QuestionStepper></QuestionStepper> -->
          <MappingFormList></MappingFormList>
        </template>

        <!-- All Courses / Mapping forms - Template 3 -->
        <template v-if="mappingTemplate == 3">
          <v-btn @click="changingTemplate(1)">X</v-btn>
          <v-btn
            @click="
              $refs.openMappingDialog.openingMappingForm(196, userDetails)
            "
            large
            dark
            class="mt-15"
          >
            {{ $t("extrakeys.startTheSelfAssessmentTest") }}
          </v-btn>
          <MappingToolMainComponent ref="openMappingDialog" />
        </template>

        <!-- Recommended Courses / Mapping forms, based on question Stepper - Template 4 -->
        <template v-if="mappingTemplate == 4">
          <v-btn @click="changingTemplate(1)">X</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuestionStepper from "@/components/TestAndDevelopment/questionStepper.vue";
import MappingToolMainComponent from "@/components/MappingTool/MappingToolMainComponent.vue";
import MappingFormList from "@/components/TestAndDevelopment/mappingFormList.vue";
export default {
  components: {
    QuestionStepper,
    MappingToolMainComponent,
    MappingFormList,
  },
  data() {
    return {
      mappingTemplate: 1,
      userIsLoggedIn: "",
      userData: "",
      userDetails: {
        nameOfUser: "",
        emailOfUser: "",
      },
    };
  },
  mounted() {
    this.checkIfLoggedIn();
  },
  methods: {
    changingTemplate(templateID) {
      this.mappingTemplate = templateID;
    },
    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.userData = userData;
      if (userData) {
        this.userIsLoggedIn = true;
        // setTimeout(() => {  location.reload(); }, 100);
        this.extractUserData(userData);
      } else {
        this.userIsLoggedIn = false;
      }
    },

    // Extract the user's name and email from the userData to use in the mappingTool
    extractUserData(userData) {
      this.userDetails.nameOfUser = userData.fname + " " + userData.lname;
      this.userDetails.emailOfUser = userData.email;
    },
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}
</style>
