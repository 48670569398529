<template>
  <div class="">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="transparent" rounded outlined v-on="on" v-model="$i18n.locale">
          <v-img v-if="$i18n.locale == 'en'" src="../../assets/flags/england.png" alt="English Flag" max-height="25" max-width="30" contain></v-img>
          <v-img v-if="$i18n.locale == 'nb'" src="../../assets/flags/norway.png" alt="Norwegian Flag" max-height="19" max-width="30" contain></v-img>
          <v-img v-if="$i18n.locale == 'pl'" src="../../assets/flags/polen.png" alt="Polish Flag" max-height="19" max-width="30" contain></v-img>
          <v-img v-if="$i18n.locale == 'es'" src="../../assets/flags/spain.png" alt="Spian Flag" max-height="19" max-width="30" contain></v-img>
          <v-img v-if="$i18n.locale == 'el'" src="../../assets/flags/greece.png" alt="Greece Flag" max-height="19" max-width="30" contain></v-img>
          <v-img v-if="$i18n.locale == 'de'" src="../../assets/flags/germany.png" alt="Dutch Flag" max-height="19" max-width="30" contain></v-img>
           <span>{{$i18n.locale}}</span>
          <v-icon class="pl-3">mdi-menu-down</v-icon> 
        </v-btn>
      </template>

      <v-list style="background-color: #f5f5f5;">
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :key="i" @click="setNewLanguage(item)">
            <v-list-item-content class="text-center">
              <v-list-item-title v-model="$i18n.locale">
                <v-row>
                  <v-col cols="5">
                    <v-img v-if="item.text == 'nb'" :src="item.flag" alt="Norwegian Flag" max-height="19" max-width="30" contain></v-img>
                    <v-img v-if="item.text == 'en'" :src="item.flag" alt="English Flag" max-height="25" max-width="30" contain></v-img>
                    <v-img v-if="item.text == 'pl'" :src="item.flag" alt="Polish Flag" max-height="20" max-width="25" contain></v-img>
                    <v-img v-if="item.text == 'es'" :src="item.flag" alt="Spanish Flag" max-height="35" max-width="40" contain></v-img>
                    <v-img v-if="item.text == 'el'" :src="item.flag" alt="Greece Flag" max-height="35" max-width="40" contain></v-img>
                    <v-img v-if="item.text == 'de'" :src="item.flag" alt="Dutch Flag" max-height="35" max-width="40" contain></v-img>
                  </v-col>
                  <v-col cols="2">
                    {{item.text}} 
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'SelectLocale',
  data() {
    return { 
      selectedLanguage: this.$store.getters.getAppLanguage,
      items:[
        {text: 'nb', country: "Norway", flag: require('@/assets/flags/norway.png')},
        {text: 'en', country: "England", flag: require('@/assets/flags/england.png')},
        {text: 'pl', country: "Polen", flag: require('@/assets/flags/polen.png')},
        {text: 'es', country: "Spain", flag: require('@/assets/flags/spain.png')},
        {text: 'el', country: "Greece", flag: require('@/assets/flags/greece.png')},
        {text: 'de', country: "Dutch", flag: require('@/assets/flags/germany.png')},
      ]
    }
  },
  methods:{
 
    // Change language version 1 - not in use but kept just in case something breaks
    setLanguage (item) {
      if (item.text == 'en') {
        this.$i18n.locale = 'en'
        this.$store.commit('setAppLanguage', 'en')
        location.reload();   
      } else if (item.text == 'nb') {
        this.$i18n.locale = 'nb'
        this.$store.commit('setAppLanguage', 'nb')  
        location.reload();   
      } else if (item.text == 'pl') {
        this.$i18n.locale = 'pl'
        this.$store.commit('setAppLanguage', 'pl')  
        location.reload();   
      } else if (item.text == 'es') {
        this.$i18n.locale = 'es'
        this.$store.commit('setAppLanguage', 'es')  
        location.reload();   
      } else if (item.text == 'el') { 
        this.$i18n.locale = 'el'
        this.$store.commit('setAppLanguage', 'el')  
        location.reload();   
      } else if (item.text == 'de') { 
        this.$i18n.locale = 'de'
        this.$store.commit('setAppLanguage', 'de')  
        location.reload();   
      }
    },

    /*
      Change language version 2
        - Minimised and simplified the code, will now do the same as version 2.
        - But not tested enough just yet. 
    */
    setNewLanguage(item){
      this.$i18n.locale = item.text;
      this.$store.commit('setAppLanguage', item.text);
      location.reload();
    }
  }
}
</script>
