import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Newss from '../views/News.vue'
import TrainingModules from '../views/TrainingModules.vue'
import DigitalLearningDesign from '../views/DigitalLearningDesign.vue'
import DigitalPedagogicalStrategy from '../views/DigitalPedagogicalStrategy.vue'
import Resources from '../views/Resources.vue'
import RSSfeed from '../views/RssFeed.vue'
import Partners from '../views/Partner.vue'
import HomeSMAP from '../views/smab/SmabHome.vue'
import AboutSMAP from '../views/smab/SmabAbout.vue'
import NewsOneSMAP from '../views/smab/SmabNewsOne.vue'
import TrainingModulesSMAP from '../views/smab/SmabTrainingModules.vue'
import ResourcesSMAB from '../views/smab/SmabResources.vue'
import TestPage from '../views/test.vue'
import MappingForm from '../views/mappingForm.vue'
import RecommendedCourses from '../views/recommendedCourses.vue'
import SelfAssessment from '../views/selfAssessment.vue'
import AllCourses from '../views/allCourses.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  { path: '/partners', name: 'Partner', component: Partners },
  { path: '/news', name: 'News', component: Newss },
  { path: '/trainingmodules', name: 'TrainingModules', component: TrainingModules },
  { path: '/digitallearningdesign', name: 'DigitalLearningDesign', component: DigitalLearningDesign },
  { path: '/digitalpedagogicalstrategy', name: 'DigitalPedagogicalStrategy', component: DigitalPedagogicalStrategy },
  { path: '/resources', name: 'Resources', component: Resources },
  { path: '/rss', name: 'RSS', component: RSSfeed },
  // { path: '/test', name: 'Test', component: ResourcesSMAB },
  { path: '/test', name: 'Test', component: TestPage },
  { path: '/mapping', name: 'mapping', component: MappingForm },
  { path: '/recommended', name: 'Recommended', component: RecommendedCourses },
  { path: '/selfassessment', name: 'Selfassessment', component: SelfAssessment },
  { path: '/courses', name: 'AllCourses', component: AllCourses },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
