<template>
  <div>
    <v-row>
      <!-- Cards -->
      <v-col cols="12" class="mt-10"></v-col>
      <v-col cols="12">
        <p class="text-center title">{{ $t("resources.headline") }}</p>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
        <v-row>
          <v-col
            v-for="(item, index) in resourceFolders"
            :Key="index"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <v-card @click="displayResource(item.template)">
              <!-- <pre>{{item}}</pre> -->
              <p
                v-if="
                  item.title == 'Video' ||
                  item.title == 'Videos' ||
                  item.title == 'Βίντεο' ||
                  item.title == 'Wideo'
                "
                class="text-center title pt-10 pb-10"
              >
                {{ $t("resources.video") }}
              </p>
              <p
                v-else-if="
                  item.title == 'Files' ||
                  item.title == 'Filer' ||
                  item.title == 'Αρχεία' ||
                  item.title == 'Archivos' ||
                  item.title == 'Pliki' ||
                  item.title == 'Dateien'
                "
                class="text-center title pt-10 pb-10"
              >
                {{ $t("resources.files") }}
              </p>
              <!-- <p v-else-if="item.title == 'Courses' || item.title == 'Kurs'" class="text-center title pt-10 pb-10">{{ $t('resources.courses') }}</p>
              <p v-else-if="item.title == 'Podcast' || item.title == 'Podcast'" class="text-center title pt-10 pb-10">{{ $t('resources.podcast') }}</p> -->
              <!-- <p v-else-if="item.title == 'Other' || item.title == 'Lyd'" class="text-center title pt-10 pb-10">Other</p> -->
              <p
                v-else-if="
                  item.title == 'External Links' || item.title == 'Annet'
                "
                class="text-center title pt-10 pb-10"
              >
                {{ $t("additional.external news") }}
              </p>
              <p v-else></p>
              <v-divider
                class="newsCardDividerPositioning"
                width="98%"
                style="padding: 2px"
                :style="`background-color:${colorArr[index]}`"
              ></v-divider>
              <v-col
                cols="12"
                class="ma-0 pa-0"
                style="padding-top: 0.1px !important"
              ></v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mt-10"></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-col>

      <!-- Resource Content -->
      <v-col cols="12" class="pt-5 mb-15">
        <v-card flat>
          <v-row class="ma-0 pa-0">
            <!-- Default -->
            <template v-if="resourceTemplate == 0">
              <v-col cols="12"> </v-col>
            </template>

            <!-- No content added yet -->
            <template v-else-if="resourceContent == ''">
              <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
                <p class="text-end title">
                  {{ $t("resources.no content message") }}
                </p>
              </v-col>
              <v-col
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="12"
                xs="12"
                class="d-flex align-end flex-column"
              >
                <v-btn class="mr-5" @click="closeTheResourceBox()">X</v-btn>
              </v-col>
            </template>

            <!-- Display Content -->
            <template v-else-if="resourceContent">
              <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
              <v-col
                cols="12"
                xl="5"
                lg="5"
                md="5"
                sm="12"
                xs="12"
                class="pb-0"
              >
                <p class="title" v-if="resourceTemplate == 1">
                  {{ $t("resources.video") }}
                </p>
                <p class="title" v-else-if="resourceTemplate == 2">
                  {{ $t("resources.files") }}
                </p>
                <!-- <p class=" title" v-else-if="resourceTemplate == 3">Courses</p>
                <p class=" title" v-else-if="resourceTemplate == 4">Podcast</p> -->
                <!-- <p class=" title" v-else-if="resourceTemplate == 5">Other</p> -->
                <p class="title" v-else-if="resourceTemplate == 6">
                  {{ $t("additional.external news") }}
                </p>
              </v-col>
              <v-col cols="12" class="pt-0"></v-col>
              <v-col cols="12" xl="1" lg="1" md="1" sm="12" xs="12"></v-col>
              <v-col
                cols="12"
                xl="11"
                lg="11"
                md="11"
                sm="12"
                xs="12"
                v-if="resourceTemplate == 1"
                class="ma-0 pa-0"
              >
                <v-row class="ma-0 pa-0">
                  <v-col
                    v-for="(youtubeVideo, youtubeIndex) in youtubeVideos"
                    :key="youtubeIndex"
                    cols="12"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="12"
                    xs="12"
                    class="ma-0 pa-0 ma-2"
                  >
                    <v-card class="pb-1" height="100%" flat>
                      <p
                        style="font-weight: bold; font-size: 22px; height: 80px"
                      >
                        {{ youtubeVideo.title }}
                      </p>
                      <!-- An Iframe which take an iterated value, and displays it -->
                      <iframe
                        width="100%"
                        height="300"
                        :src="youtubeVideo.link"
                        :title="youtubeVideo.title"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                xl="11"
                lg="11"
                md="11"
                sm="12"
                xs="12"
                v-if="resourceTemplate == 2"
                class="ma-0 pa-0"
              >
                <!-- Final Reports -->
                <h3 class="ml-5 mb-5">{{ $t("extrakeys.finalReports") }}</h3>
                <v-row class="ma-0 pa-0">
                  <v-col
                    v-for="(finalReport, finalReportIndex) in filteredReports"
                    :key="finalReportIndex"
                    cols="12"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="12"
                    xs="12"
                    class="ma-0 pa-0 ma-2"
                  >
                    <v-card
                      flat
                      :href="finalReport.link"
                      target="_blank"
                      height="100%"
                    >
                      <p>
                        <span class="pr-2">
                          <v-icon>mdi-open-in-new</v-icon>
                        </span>
                        <span>{{ finalReport.title }}</span>
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
                <div
                  style="
                    width: 100%;
                    margin: 0 auto;
                    height: 1px;
                    background-color: #000;
                  "
                  class="mb-10 mt-5"
                ></div>
                <!-- Articles -  articleLinks -->
                <h3 class="ml-5 mb-5">{{ $t("extrakeys.articles") }}</h3>
                <v-row class="ma-0 pa-0">
                  <v-col
                    v-for="(article, articleIndex) in filteredArticles"
                    :key="articleIndex"
                    cols="12"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="12"
                    xs="12"
                    class="ma-0 pa-0 ma-2"
                  >
                    <v-card
                      flat
                      :href="article.link"
                      target="_blank"
                      height="100%"
                    >
                      <p>
                        <span class="pr-2">
                          <v-icon>mdi-open-in-new</v-icon>
                        </span>
                        <span>{{ article.title }}</span>
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
                <div
                  style="
                    width: 100%;
                    margin: 0 auto;
                    height: 1px;
                    background-color: #000;
                  "
                  class="mb-10 mt-5"
                ></div>

                <!-- Newsletters -->
                <h3 class="ml-5 mb-5">{{ $t("extrakeys.newsletters") }}</h3>
                <v-row class="ma-0 pa-0">
                  <v-col
                    v-for="(newsletter, newsletterIndex) in filteredNewsletters"
                    :key="newsletterIndex"
                    cols="12"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="12"
                    xs="12"
                    class="ma-0 pa-0 ma-2"
                  >
                    <v-card
                      flat
                      :href="newsletter.link"
                      target="_blank"
                      height="100%"
                    >
                      <p>
                        <span class="pr-2">
                          <v-icon>mdi-open-in-new</v-icon>
                        </span>
                        <span>{{ newsletter.title }}</span>
                      </p>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- pressReleaseLinks Press Relase -->
                <v-row class="ma-0 pa-0">
                  <v-col
                    v-for="(
                      pressRelease, pressReleaseIndex
                    ) in filteredPressRelases"
                    :key="pressReleaseIndex"
                    cols="12"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="12"
                    xs="12"
                    class="ma-0 pa-0 ma-2"
                  >
                    <v-card
                      flat
                      :href="pressRelease.link"
                      target="_blank"
                      height="100%"
                    >
                      <p>
                        <span class="pr-2">
                          <v-icon>mdi-open-in-new</v-icon>
                        </span>
                        <span>{{ pressRelease.title }}</span>
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <template v-for="(resources, resourceIndex) in resourceContent">
                <!-- Courses -->
                <template
                  v-if="(resourceTemplate == 3) & (resources.type == 'courses')"
                >
                  <v-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    :key="resourceIndex"
                  >
                  </v-col>
                </template>

                <!-- Podcast -->
                <template
                  v-else-if="
                    (resourceTemplate == 4) & (resources.type == 'podcast')
                  "
                >
                  <v-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    :key="resourceIndex"
                  >
                  </v-col>
                </template>

                <!-- Sound -->
                <template
                  v-else-if="
                    (resourceTemplate == 5) & (resources.type == 'sound')
                  "
                >
                  <v-col
                    cols="12"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                    :key="resourceIndex"
                  >
                  </v-col>
                </template>

                <!-- Other -->
                <template
                  v-else-if="
                    (resourceTemplate == 6) &
                    (resources.type == 'external_content')
                  "
                >
                  <v-col
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                    :key="resourceIndex"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        class="pa-5 pb-1"
                        :elevation="hover ? 12 : 2"
                        :href="resources.content"
                        target="_blank"
                      >
                        <v-row>
                          <!-- Image comes, and Logo comes here when created (make a new col) -->
                          <v-col
                            cols="12"
                            class="ma-0 pa-0"
                            style="height: 40px"
                          >
                            <v-card-actions class="ma-0 pa-0">
                              <v-spacer></v-spacer>
                              <v-img
                                v-if="resources.language == 'Norsk'"
                                src="/flags/norway.png"
                                alt="Norwegian Flag"
                                max-height="38"
                                max-width="60"
                                contain
                                class="mb-1"
                              ></v-img>
                              <v-img
                                v-else-if="resources.language == 'English'"
                                src="/flags/england.png"
                                alt="English Flag"
                                max-height="38"
                                max-width="60"
                                contain
                                class="mb-1"
                              ></v-img>
                            </v-card-actions>
                          </v-col>
                          <v-col cols="12" class="pb-0">
                            <!-- Title -->
                            <p
                              class="resourceCardTitle"
                              style="font-size: 16px; height: 20px"
                              v-if="$vuetify.breakpoint.width <= 959"
                            >
                              {{ resources.title }}
                            </p>
                            <p
                              class="resourceCardTitle"
                              style="font-size: 14px; height: 80px"
                              v-else-if="
                                $vuetify.breakpoint.width <= 1300 &&
                                $vuetify.breakpoint.width >= 960
                              "
                            >
                              {{ resources.title }}
                            </p>
                            <p
                              class="resourceCardTitle"
                              style="font-size: 18px; height: 90px"
                              v-else
                            >
                              {{ resources.title }}
                            </p>
                            <!-- Description -->
                            <p class="resourceDescription" style="height: 90px">
                              {{ resources.description }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="pt-0 mt-0 pb-0">
                            <v-card-actions class="pt-0 mt-0">
                              <p class="rssCardButton mr-2 pt-3">
                                {{ $t("additional.see video") }}
                              </p>
                              <v-icon class="rssCardButtonArrow"
                                >mdi-arrow-right</v-icon
                              >
                            </v-card-actions>
                          </v-col>
                        </v-row>
                        <v-divider
                          class="newsCardDividerPositioning"
                          width="98%"
                          style="padding: 2px"
                        ></v-divider>
                      </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </template>
            </template>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: "",
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      colorArr: [
        "#205072",
        "#329D9C",
        "#D83636",
        "#DD9A30",
        "#205072",
        "#329D9C",
      ],
      resourceFolders: [
        {
          title: this.$t("resources.video"),
          colour: "",
          image: "",
          description: "",
          template: 1,
        },
        {
          title: this.$t("resources.files"),
          colour: "",
          image: "",
          description: "",
          template: 2,
        },
        // { title: this.$t('resources.courses'), colour: "", image: "", description: "", template: 3 },
        // { title: this.$i18n.t('resources.podcast'), colour: "", image: "", description: "", template: 4 },
        // { title: "Other", colour: "", image: "", description: "", template: 5 },
        {
          title: "External Links",
          colour: "",
          image: "",
          description: "",
          template: 6,
        },
      ],
      resourceTemplate: 0,
      resourceContent: [],

      finalReports: [
        // Norway
        {
          title: "Norwegian Final Report IO1 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/de48393f-f25e-4c54-a1c4-3b6b92757b54.pdf",
          language: "nb",
        },
        // English
        {
          title: "English Final Report IO1 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/728af307-1ab8-4aae-a4ee-db414b51f8e5.pdf",
          language: "en",
        },
        {
          title: "English Final Report IO2 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/36ffe2ef-f56e-4edd-a365-750ed5ca1c5d.pdf",
          language: "en",
        },
        // Polen
        {
          title: "Polish Final Report IO1 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/63a2e3ca-21ec-4517-9425-09955d5559f8.pdf",
          language: "pl",
        },
        // Spain
        {
          title: "Spain Final Report IO1 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/36694416-6a0c-4142-bfb3-60a135ba8452.pdf",
          language: "es",
        },
        // Greek
        {
          title: "Greek Final Report IO1 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/41e1139c-a157-43a4-a122-0090449416a1.pdf",
          language: "el",
        },
        // German
        {
          title: "German Final Report IO1 SMAB",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/13e6875c-2132-4b3e-aad4-004c8817132c.pdf",
          language: "de",
        },
      ],

      // Not sure about endpoint, and need to be done by today.
      articleLinks: [
        // English
        {
          title: "Smab Article Jan 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/0a1891e0-ebcc-421b-82a5-49e2f11d2f63.pdf",
          language: "en",
        },
        {
          title: "Smab Article Mar 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/26061130-b7b7-4ce0-9d89-b21ec057e592.pdf",
          language: "en",
        },
        {
          title: "Smab Article Jul 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/c60824b1-bbcb-44c6-9a43-41f8341ddcd6.pdf",
          language: "en",
        },
        {
          title: "Smab Article Aug 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/dea44f30-191f-4d8a-a2a9-b4dc28f85a28.pdf",
          language: "en",
        },
        {
          title: "Smab Article Sept 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f2ceabc8-3370-400f-b59c-a2edd2fc136b.pdf",
          language: "en",
        },
        {
          title: "Smab Article Oct 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/375412ee-68f9-4bba-81dc-41a1e6c9b198.pdf",
          language: "en",
        },
        {
          title: "Smab Article Nov 23",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ab7e27f2-ad59-46f7-851f-b52d679907f4.pdf",
          language: "en",
        },
        // Norway
        {
          title: "Smab Article Jan 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/b2b9d19a-382c-4c11-8dbb-64022a9721d5.pdf",
          language: "nb",
        },
        {
          title: "Smab Article Mar 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/8665e576-2f1d-472a-a0f5-9d55ff87171d.pdf",
          language: "nb",
        },
        {
          title: "Smab Article Jun 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/e3841f74-edcc-4b75-8144-361dc052fd58.pdf",
          language: "nb",
        },
        {
          title: "Smab Article Jun 23 - NO",
          link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F9671932c-7feb-42fe-93ff-16909ff7c402.docx&wdOrigin=BROWSELINK",
          language: "nb",
        },
        {
          title: "Smab Article Aug 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/1b512270-05c8-4d04-b289-1057cbc1f352.pdf",
          language: "nb",
        },
        {
          title: "Smab Article Sept 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/757072bf-cad0-43e6-869c-b0c50fdfa2a2.pdf",
          language: "nb",
        },
        {
          title: "Smab Article Oct 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/10e92d5a-32ed-48f7-a13c-f4506347c8f5.pdf",
          language: "nb",
        },
        {
          title: "Smab Article Nov 23 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/0aeb21d0-35c0-4cf8-bcd4-ea6744a98794.pdf",
          llanguage: "nb",
        },
        // Polen
        {
          title: "Smab Article Jan 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/b139d522-6257-4b10-89f2-79c18c3efa72.pdf",
          language: "pl",
        },
        {
          title: "Smab Article Mar 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/aa6adedf-0b4c-450c-bd81-cc7bc464427a.pdf",
          language: "pl",
        },
        {
          title: "Smab Article Jun 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/6cdfc376-f20d-4902-8b24-f275529ebdab.pdf",
          language: "pl",
        },
        {
          title: "Smab Article Jun 23 - PL",
          link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F78ab3e7c-d1f0-45c7-9583-aea816e889c3.docx&wdOrigin=BROWSELINK",
          language: "pl",
        },
        {
          title: "Smab Article Aug 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/68028b92-b1b4-408f-b8dd-9a0b6df32f00.pdf",
          language: "pl",
        },
        {
          title: "Smab Article Sept 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ee725558-1509-459f-86f4-3056ea875137.pdf",
          language: "pl",
        },
        {
          title: "Smab Article Oct 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4f67e8ce-5bb0-4e82-a579-289e9e79318f.pdf",
          language: "pl",
        },
        {
          title: "Smab Article Nov 23 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/becd94ac-3196-4c15-b58e-ac72b45f04e1.pdf",
          language: "pl",
        },
        // Spain
        {
          title: "Smab Article Jan 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/62ca339b-21b7-4142-984c-b95e0c6e045a.pdf",
          language: "es",
        },
        {
          title: "Smab Article Mar 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/171e7fdb-d417-4539-b7f4-7cc34990d93f.pdf",
          language: "es",
        },
        {
          title: "Smab Article Jun 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d8f315ac-6bcc-4407-9f55-c6b55fda94d6.pdf",
          language: "es",
        },
        {
          title: "Smab Article Jun 23 - ES",
          link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F117f5d16-0222-4b6b-8dc5-a6392eda3385.docx&wdOrigin=BROWSELINK",
          language: "es",
        },
        {
          title: "Smab Article Aug 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/9d6de701-e790-4c8c-8c92-df1828140dba.pdf",
          language: "es",
        },
        {
          title: "Smab Article Sept 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/a764e5a6-cccc-48a5-a266-c7fe0c74c0d4.pdf",
          language: "es",
        },
        {
          title: "Smab Article Oct 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/3d604e4b-a814-4732-947d-1d3dae8bc101.pdf",
          language: "es",
        },
        {
          title: "Smab Article Nov 23 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/b124363f-9564-4ade-afa8-958b53221041.pdf",
          language: "es",
        },
        // Greek
        {
          title: "Smab Article Jan 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/318e26b2-5548-4146-9d56-bdf589cc45fb.pdf",
          language: "el",
        },
        {
          title: "Smab Article Mar 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4773d256-b160-4025-a5fe-fb4077c3807a.pdf",
          language: "el",
        },
        {
          title: "Smab Article Jun 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/1ad1cfbb-4f21-40e3-b8f6-80ce135e64e1.pdf",
          language: "el",
        },
        {
          title: "Smab Article Jun 23 - GR",
          link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F977a55fa-d69e-4554-8923-0b01c910931c.docx&wdOrigin=BROWSELINK",
          language: "el",
        },
        {
          title: "Smab Article Aug 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/9957d5c0-4068-4154-a69b-23ba6184200d.pdf",
          language: "el",
        },
        {
          title: "Smab Article Sept 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/e5b3fc82-1846-4e8c-8436-d7beba68445a.pdf",
          language: "el",
        },
        {
          title: "Smab Article Oct 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/c4b4d062-6ec6-4d3c-93c9-9abf785cc796.pdf",
          language: "el",
        },
        {
          title: "Smab Article Nov 23 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/811342ce-6e37-45e8-abbf-d1ebe04b4871.pdf",
          llanguage: "el",
        },
        // German
        {
          title: "Smab Article Jan 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d826ef77-40d9-4e02-bf82-2f6bd22f9ee0.pdf",
          language: "de",
        },
        {
          title: "Smab Article Mar 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/85387ae6-96ce-447a-b847-951e1cda79b8.pdf",
          language: "de",
        },
        {
          title: "Smab Article Jun 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/e1d84571-68e9-45b7-b8a6-88f018943aa0.pdf",
          language: "de",
        },
        {
          title: "Smab Article Jun 23 - DE",
          link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F91311e43-2fac-4cfc-bbed-03456bfcd7ab.docx&wdOrigin=BROWSELINK",
          language: "de",
        },
        {
          title: "Smab Article Aug 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/3593ed4f-4358-405e-b6ef-7459eee0676e.pdf",
          language: "de",
        },
        {
          title: "Smab Article Sept 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/6916dc8c-a60f-451c-9c00-e1b228681323.pdf",
          language: "de",
        },
        {
          title: "Smab Article Oct 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/45a9f8a2-233b-44b0-a663-b1c04259876b.pdf",
          language: "de",
        },
        {
          title: "Smab Article Nov 23 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/2074cd60-b7b3-4e58-bc68-7ee859513471.pdf",
          llanguage: "de",
        },
      ],

      newsletterLinks: [
        // English
        {
          title: "Newsletter No 1",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/753ab541-118a-41a4-9d27-06eddf9cc315.pdf",
          language: "en",
        },
        {
          title: "Newsletter No 2",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/a58b1e84-2ed3-4021-976f-e81deead721b.pdf",
          language: "en",
        },
        {
          title: "Newsletter No 3",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4411d34f-7188-49ab-92f9-a7f24bf8e08e.pdf",
          language: "en",
        },
        {
          title: "Newsletter No 4",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f8bc5872-e559-443e-8fb4-3488b43bc758.pdf",
          language: "en",
        },
        {
          title: "Newsletter No 5",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/c0fb5d8e-7be4-4fc0-ad10-c64b7cabe4bb.pdf",
          language: "en",
        },
        {
          title: "Newsletter No 6",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/cb70762e-744f-43ee-93c7-6f3292ee75f4.pdf",
          language: "en",
        },
        // Norwegian
        {
          title: "Newsletter No 1 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/287c4f17-278a-42df-a65d-3c7f51170991.pdf",
          language: "nb",
        },
        {
          title: "Newsletter No 2 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/dbc6c1aa-8625-4871-bf77-88e7bde5d7ab.pdf",
          language: "nb",
        },
        {
          title: "Newsletter No 3 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4984e9af-1905-402d-90a8-3c144fbcd673.pdf",
          language: "nb",
        },
        {
          title: "Newsletter No 4 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/7eb64568-988e-4a40-9a22-a9a5c121dd26.pdf",
          language: "nb",
        },
        {
          title: "Newsletter No 5 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/3e2e0d24-d3d5-472c-986d-1055276fa4fd.pdf",
          language: "nb",
        },
        {
          title: "Newsletter No 6 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/b4b3135a-2023-4d0f-bc59-18415c407d34.pdf",
          language: "nb",
        },
        // Polish
        {
          title: "Newsletter No 1 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/9db76cac-6061-4a86-a128-cf26715de4bd.pdf",
          language: "pl",
        },
        {
          title: "Newsletter No 2 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/afe31a8f-62d5-4129-8558-3943a6c484ad.pdf",
          language: "pl",
        },
        {
          title: "Newsletter No 3 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/857bf315-993b-4c65-a440-f67a64216d49.pdf",
          language: "pl",
        },
        {
          title: "Newsletter No 4 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/91c2c8b6-09e3-4a56-97e1-4d2211b5f892.pdf",
          language: "pl",
        },
        {
          title: "Newsletter No 5 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/583d67da-d985-4ef3-a4f5-f1b00c3dabe9.pdf",
          language: "pl",
        },
        {
          title: "Newsletter No 6 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/2eefe458-a0de-4027-91fe-805f43ea5a3e.pdf",
          language: "pl",
        },
        // Spain
        {
          title: "Newsletter No 1 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/61c91e1f-7bf6-49ca-8063-3ec02f4914d4.pdf",
          language: "es",
        },
        {
          title: "Newsletter No 2 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/9c2d72bd-91f7-4d7f-afe4-700a5042d9ce.pdf",
          language: "es",
        },
        {
          title: "Newsletter No 3 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/b5420450-7753-4e3d-a1a4-81fa8006627f.pdf",
          language: "es",
        },
        {
          title: "Newsletter No 4 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/83f4af78-7288-494f-8f98-903d928b4a04.pdf",
          language: "es",
        },
        {
          title: "Newsletter No 5 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/db924021-5536-407b-85f1-fdbe8086fec8.pdf",
          language: "es",
        },
        {
          title: "Newsletter No 6 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f3cfd9dd-8a02-4a47-b3a4-608012bb93e1.pdf",
          language: "es",
        },
        // Greek
        {
          title: "Newsletter No 1 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/465b86e9-a60b-45ee-815d-fcad66a84a0c.pdf",
          language: "el",
        },
        {
          title: "Newsletter No 2 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/cf220e24-aeb5-4b70-b058-2146974543d5.pdf",
          language: "el",
        },
        {
          title: "Newsletter No 3 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/5b0b1870-2120-4148-8bac-d72b99d210ab.pdf",
          language: "el",
        },
        {
          title: "Newsletter No 4 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/21697cae-f880-4e92-827d-65e896123bd3.pdf",
          language: "el",
        },
        {
          title: "Newsletter No 5 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/07f9acd6-c97b-4f90-9a82-9de8be5c7dba.pdf",
          language: "el",
        },
        {
          title: "Newsletter No 6 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/c48af420-097d-4889-9eee-74eda55bc8e3.pdf",
          language: "el",
        },
        // German
        {
          title: "Newsletter No 1 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4d866005-4651-4108-8261-a13fcaa43f8a.pdf",
          language: "de",
        },
        {
          title: "Newsletter No 2 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/8f0c5962-dae6-47e6-8c6f-a1af3785c9c9.pdf",
          language: "de",
        },
        {
          title: "Newsletter No 3 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4e363f98-c215-424e-9861-efc504ada365.pdf",
          language: "de",
        },
        // {
        //   title: "Newsletter No 4 - DE",
        //   link: "KEY",
        //   language: "de",
        // },
        // {
        //   title: "Newsletter No 5 - DE",
        //   link: "KEY",
        //   language: "de",
        // },
        // {
        //   title: "Newsletter No 6 - DE",
        //   link: "KEY",
        //   language: "de",
        // },
      ],
      pressReleaseLinks: [
        // English
        {
          title: "Press Release 1",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/4b30bb24-2430-4aaf-a013-52107b9fbd11.pdf",
          language: "en",
        },
        {
          title: "Press Release 2",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/deb5ab34-f0f0-4b98-b350-ff7ca621529f.pdf",
          language: "en",
        },
        // Norwegian
        {
          title: "Press Release 1  - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/29ef929a-8dc4-4a6c-85ba-053f72d94bf2.pdf",
          language: "nb",
        },
        {
          title: "Press Release 2 - NO",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/a9294f85-2591-42f5-bb85-5f1da4b1a419.pdf",
          language: "nb",
        },
        // Polish
        {
          title: "Press Release 1 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ed2d790f-6f0c-4be5-be45-a68bad63bed7.pdf",
          language: "pl",
        },
        {
          title: "Press Release 2 - PL",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/260959a7-1dde-49ad-a5e2-d2e0b96b4f6e.pdf",
          language: "pl",
        },
        // Spain
        {
          title: "Press Release 1 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f486cbe8-4e11-4c80-92f2-f6e4b262a561.pdf",
          language: "es",
        },
        {
          title: "Press Release 2 - ES",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/333ebd74-15c4-4a8e-a41f-cbcfab0b0bd4.pdf",
          language: "es",
        },
        // Greek
        {
          title: "Press Release 1 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/e219254f-2978-4d53-b29b-2776777e9bdb.pdf",
          language: "el",
        },
        {
          title: "Press Release 2 - GR",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/19f648dc-a95d-49d4-9d9e-840c7a5d1eca.pdf",
          language: "el",
        },
        // German
        {
          title: "Press Release 1 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/9b02ea73-a316-447b-9165-4d9d5286f7da.pdf",
          language: "de",
        },
        {
          title: "Press Release 2 - DE",
          link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/1057b00e-94a2-40fa-bdf1-96a1d97a2f99.pdf",
          language: "de",
        },
      ],
      youtubeVideos: [
        {
          title: this.$t("youtubeVideos.videoTitleOne"),
          description: this.$t("youtubeVideos.videoDescriptionOne"),
          link: "https://www.youtube.com/embed/lSzyLllPp0Q?si=SuniuIEnEm635jAs",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwo"),
          description: this.$t("youtubeVideos.videoDescriptionTwo"),
          link: "https://www.youtube.com/embed/hqfQA4BNjVI?si=SZogwI7qCMNlep4G",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThree"),
          description: this.$t("youtubeVideos.videoDescriptionThree"),
          link: "https://www.youtube.com/embed/rWMHSgq4ua0?si=eqbEx_YQyllxy6IH",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFour"),
          description: this.$t("youtubeVideos.videoDescriptionFour"),
          link: "https://www.youtube.com/embed/LTb8qOcy360?si=KpVtW2ImyhGw7OSo",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFive"),
          description: this.$t("youtubeVideos.videoDescriptionFive"),
          link: "https://www.youtube.com/embed/Wrf0SZALL-E?si=CAGveWoZyqaphPWI",
        },
        {
          title: this.$t("youtubeVideos.videoTitleSix"),
          description: this.$t("youtubeVideos.videoDescriptionSix"),
          link: "https://www.youtube.com/embed/uAn58F7EM4w?si=kVHRdXjpsGQZD6yA",
        },
        {
          title: this.$t("youtubeVideos.videoTitleSeven"),
          description: this.$t("youtubeVideos.videoDescriptionSeven"),
          link: "https://www.youtube.com/embed/aY7OFdP0ZTA?si=8Sr9RULxBzUZ73_U",
        },
        {
          title: this.$t("youtubeVideos.videoTitleEight"),
          description: this.$t("youtubeVideos.videoDescriptionEight"),
          link: "https://www.youtube.com/embed/kyXBkfaGnY4?si=SrJOlWJsM4yH8L23",
        },
        {
          title: this.$t("youtubeVideos.videoTitleNine"),
          description: this.$t("youtubeVideos.videoDescriptionNine"),
          link: "https://www.youtube.com/embed/ip2E967ZFCM?si=DxVSMn_RerNx7ef4",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTen"),
          description: this.$t("youtubeVideos.videoDescriptionTen"),
          link: "https://www.youtube.com/embed/_ZTqmlzrinA?si=sopC547ZX77fthpG",
        },
        {
          title: this.$t("youtubeVideos.videoTitleEleven"),
          description: this.$t("youtubeVideos.videoDescriptionEleven"),
          link: "https://www.youtube.com/embed/Hb3xJZCWnjA?si=BkKuMlz88sfeIAlW",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwelve"),
          description: this.$t("youtubeVideos.videoDescriptionTwelve"),
          link: "https://www.youtube.com/embed/_WvlCBHAzis?si=sBAGOIeDqow2QOTX",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirteen"),
          description: this.$t("youtubeVideos.videoDescriptionThirteen"),
          link: "https://www.youtube.com/embed/6f3AxcJUYuk?si=woh1DQmVzQhr5KNC",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourteen"),
          description: this.$t("youtubeVideos.videoDescriptionFourteen"),
          link: "https://www.youtube.com/embed/l1n5a7irueY?si=exX3JCXvU6a8tPVg",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFifteen"),
          description: this.$t("youtubeVideos.videoDescriptionFifteen"),
          link: "https://www.youtube.com/embed/QzCgZTp1Ji4?si=suY1AzuNIXAhPZsu",
        },
        {
          title: this.$t("youtubeVideos.videoTitleSixteen"),
          description: this.$t("youtubeVideos.videoDescriptionSixteen"),
          link: "https://www.youtube.com/embed/jvRVsavDd4g?si=3ELza-WDVay5UC55",
        },
        {
          title: this.$t("youtubeVideos.videoTitleSeventeen"),
          description: this.$t("youtubeVideos.videoDescriptionSeventeen"),
          link: "https://www.youtube.com/embed/DCgm9FfV7Cg?si=8kleIpY1B7HOMjj8",
        },
        {
          title: this.$t("youtubeVideos.videoTitleEighteen"),
          description: this.$t("youtubeVideos.videoDescriptionEighteen"),
          link: "https://www.youtube.com/embed/CIwQT4ZmjYA?si=OUpCLe_wfasS08Mb",
        },
        {
          title: this.$t("youtubeVideos.videoTitleNineteen"),
          description: this.$t("youtubeVideos.videoDescriptionNineteen"),
          link: "https://www.youtube.com/embed/6hBFGon126k?si=L1nFMoCuOWge-QC4",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwenty"),
          description: this.$t("youtubeVideos.videoDescriptionTwenty"),
          link: "https://www.youtube.com/embed/A8coqORmdlk?si=FoxKBBVpPIJeqer7",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentyOne"),
          description: this.$t("youtubeVideos.videoDescriptionTwentyOne"),
          link: "https://www.youtube.com/embed/dVC0qiNL1f0?si=hpUxCSD08c0_M1M6",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentyTwo"),
          description: this.$t("youtubeVideos.videoDescriptionTwentyTwo"),
          link: "https://www.youtube.com/embed/8cCmMiStPUE?si=pSiY1TWRz5Cpv4M5",
        },
        // {
        //   title: this.$t("youtubeVideos.videoTitleTwentyThree"),
        //   description: this.$t("youtubeVideos.videoDescriptionTwentyThree"),
        //   link: "KEY",
        // },
        {
          title: this.$t("youtubeVideos.videoTitleTwentyFour"),
          description: this.$t("youtubeVideos.videoDescriptionTwentyFour"),
          link: "https://www.youtube.com/embed/s5UQmXpvBbg?si=IH1Q9YDNLEZgdSNU",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentyFive"),
          description: this.$t("youtubeVideos.videoDescriptionTwentyFive"),
          link: "https://www.youtube.com/embed/M-02Gp4yh7c?si=96PhGXxUBE739NaK",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentySix"),
          description: this.$t("youtubeVideos.videoDescriptionTwentySix"),
          link: "https://www.youtube.com/embed/zShOIsf39yo?si=ZZfi2aNov6je8KC1",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentySeven"),
          description: this.$t("youtubeVideos.videoDescriptionTwentySeven"),
          link: "https://www.youtube.com/embed/303rnkCg9gU?si=doDFsK1pZkdD_Y0e",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentyEight"),
          description: this.$t("youtubeVideos.videoDescriptionTwentyEight"),
          link: "https://www.youtube.com/embed/l152FlsG7BQ?si=QAxQ1kVzZfYhHuk_",
        },
        {
          title: this.$t("youtubeVideos.videoTitleTwentyNine"),
          description: this.$t("youtubeVideos.videoDescriptionTwentyNine"),
          link: "https://www.youtube.com/embed/tuDaMlwJBOw?si=2-gZ6TFTnhdUi_8R",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirty"),
          description: this.$t("youtubeVideos.videoDescriptionThirty"),
          link: "https://www.youtube.com/embed/wEoDfpVsy5M?si=pX62SKrxV2qGSrZb",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtyOne"),
          description: this.$t("youtubeVideos.videoDescriptionThirtyOne"),
          link: "https://www.youtube.com/embed/bTABrmlQS8Y?si=lKEm7CYlEGF82jxP",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtyTwo"),
          description: this.$t("youtubeVideos.videoDescriptionThirtyTwo"),
          link: "https://www.youtube.com/embed/sHla5dhl0-w?si=dXSZujqI655Tnd6D",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtyThree"),
          description: this.$t("youtubeVideos.videoDescriptionThirtyThree"),
          link: "https://www.youtube.com/embed/WpgB9z9gid4?si=_QfQhFpPfywjY0EN",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtyFour"),
          description: this.$t("youtubeVideos.videoDescriptionThirtyFour"),
          link: "https://www.youtube.com/embed/68Vjii3oWtk?si=Mywu4aSxpgBg86Cn",
        },
        // {
        //   title: this.$t("youtubeVideos.videoTitleThirtyFive"),
        //   description: this.$t("youtubeVideos.videoDescriptionThirtyFive"),
        //   link: "KEY",
        // },
        {
          title: this.$t("youtubeVideos.videoTitleThirtySix"),
          description: this.$t("youtubeVideos.videoDescriptionThirtySix"),
          link: "https://www.youtube.com/embed/NLgTPMdJGys?si=iX4ZoC_YDvFZ3g8Q",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtySeven"),
          description: this.$t("youtubeVideos.videoDescriptionThirtySeven"),
          link: "https://www.youtube.com/embed/4Q6D0o8IIwM?si=6IYheseXt8RZaCAM",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtyEight"),
          description: this.$t("youtubeVideos.videoDescriptionThirtyEight"),
          link: "https://www.youtube.com/embed/rhuQO2UtmmA?si=bwthQoyn_WeCdk_w",
        },
        {
          title: this.$t("youtubeVideos.videoTitleThirtyNine"),
          description: this.$t("youtubeVideos.videoDescriptionThirtyNine"),
          link: "https://www.youtube.com/embed/GqADRPJZIfI?si=62xMTC53Ezv77VFo",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourty"),
          description: this.$t("youtubeVideos.videoDescriptionFourty"),
          link: "https://www.youtube.com/embed/5cbswgvfqtc?si=F7XMsJBFYYVocu9P",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtyOne"),
          description: this.$t("youtubeVideos.videoDescriptionFourtyOne"),
          link: "https://www.youtube.com/embed/2S66QLqxePc?si=PDIZTUWMPCeTJTWe",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtyTwo"),
          description: this.$t("youtubeVideos.videoDescriptionFourtyTwo"),
          link: "https://www.youtube.com/embed/HZsmfGi6dHM?si=-oGGwxuCuMxewvI3",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtyThree"),
          description: this.$t("youtubeVideos.videoDescriptionFourtyThree"),
          link: "https://www.youtube.com/embed/NgVS0Peid6s?si=Odfgi5H6JDsH4fs6",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtyFour"),
          description: this.$t("youtubeVideos.videoDescriptionFourtyFour"),
          link: "https://www.youtube.com/embed/G95LvtajgoI?si=EDdbGAQMewQLUOgs",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtyFive"),
          description: this.$t("youtubeVideos.videoDescriptionFourtyFive"),
          link: "https://www.youtube.com/embed/1Edwo2od_aU?si=oTDKgSHPWRZ42Kj2",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtySix"),
          description: this.$t("youtubeVideos.videoDescriptionFourtySix"),
          link: "https://www.youtube.com/embed/KYQ-_syRwAc?si=WhseshhOfOGThVOp",
        },
        {
          title: this.$t("youtubeVideos.videoTitleFourtySeven"),
          description: this.$t("youtubeVideos.videoDescriptionFourtySeven"),
          link: "https://www.youtube.com/embed/8xb6uuWsqU4?si=CSvwt-1oJRopI4_J",
        },
      ],
    };
  },

  mounted() {
    this.getSelectedLanguage();
  },
  methods: {
    getSelectedLanguage() {
      this.selectedLanguage = localStorage.getItem("language");
      // console.log("Selected Language are", this.selectedLanguage);
    },
    // Close the Bottom Box
    closeTheResourceBox() {
      this.resourceTemplate = 0;
      this.resourceContent = [];
    },

    // Display the specific Resource Content
    displayResource(templateID) {
      this.resourceTemplate = templateID;
      this.getAllResources();
    },
    getAllResources() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/content?tenant_id=106`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.resourceContent = response.data;
        });
    },
  },

  computed: {
    filteredReports() {
      if (this.selectedLanguage) {
        return this.finalReports.filter(
          (report) =>
            report.language.toLowerCase() ===
            this.selectedLanguage.toLowerCase()
        );
      } else {
        return [];
      }
    },
    filteredNewsletters() {
      if (this.selectedLanguage) {
        return this.newsletterLinks.filter(
          (report) =>
            report.language.toLowerCase() ===
            this.selectedLanguage.toLowerCase()
        );
      } else {
        return [];
      }
    },
    filteredArticles() {
      if (this.selectedLanguage) {
        return this.articleLinks.filter(
          (report) => report.language === this.selectedLanguage
        );
      } else {
        return [];
      }
    },
    filteredPressRelases() {
      if (this.selectedLanguage) {
        return this.pressReleaseLinks.filter(
          (report) => report.language === this.selectedLanguage
        );
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
.newsCardDividerPositioning {
  margin-left: 1%;
  margin-bottom: 2%;
}

.resourceCardTitle {
  font-size: 20px;
  font-weight: bold;
}

.resourceDescription {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.resourcesLanguage {
  font-size: 12px;
}

.rssCardButton {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  font-size: 18px;
  text-align: left;
  color: #205072;
  opacity: 1;
  letter-spacing: 0px;
  text-decoration: underline;
}

.rssCardButtonArrow {
  color: #205072;
  opacity: 1;
}
</style>
