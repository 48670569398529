<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" class="mt-12"></v-col>
      <v-col cols="12">
        <!-- <QuestionStepper></QuestionStepper> -->
        <MappingFormList></MappingFormList>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuestionStepper from '@/components/TestAndDevelopment/questionStepper.vue'
import MappingFormList from '@/components/TestAndDevelopment/mappingFormList.vue'
export default {
  components: {
    QuestionStepper,
    MappingFormList
  },
  data() {
    return {
      
    }
  }
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
}
</style>