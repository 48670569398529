<template>
  <v-dialog
    v-model="enrollDialog"
    width="30%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card
      style="
        border-radius: 10px;
        overflow: hidden;
        background-color: rgb(234, 234, 237);
      "
    >
      <!-- Close Button -->
      <div style="position: absolute; top: 10px; right: 10px; z-index: 4">
        <v-btn fab small color="primary" @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <!-- Enroll -->
      <template v-if="onEnroll == true">
        <p class="text-center title">Register</p>
        <p class="text-center">Register a new user</p>
        <v-divider></v-divider>

        <div class="pa-3">
          <v-form ref="form" class="pt-3 pb-1" v-model="valid">
            <p class="pb-0 mb-0">First Name</p>
            <v-text-field
              @keyup.enter="validateRegisterEmail()"
              :rules="firstNameRules"
              dense
              hide-details
              v-model="submitForm.fname"
              prepend-inner-icon="mdi-account"
              outlined
              placeholder="First name"
            ></v-text-field>
            <p class="pb-0 mb-0 py-2">Last Name</p>
            <v-text-field
              @keyup.enter="validateRegisterEmail()"
              :rules="lastNameRules"
              dense
              hide-details
              v-model="submitForm.lname"
              prepend-inner-icon="mdi-account"
              outlined
              placeholder="Last name"
            ></v-text-field>
            <p class="pb-0 mb-0 py-2">Email</p>
            <v-text-field
              @keyup.enter="validateRegisterEmail()"
              :rules="emailRules"
              dense
              hide-details
              v-model="submitForm.email"
              prepend-inner-icon="mdi-email"
              outlined
              placeholder="Email"
            ></v-text-field>
            <p class="pb-0 mb-0 py-2">Password</p>
            <v-text-field
              @keyup.enter="validateRegisterEmail()"
              type="password"
              :rules="passwordRules"
              dense
              hide-details
              v-model="submitForm.password"
              prepend-inner-icon="mdi-lock"
              outlined
              placeholder="Password"
            ></v-text-field>
            <p class="pb-0 mb-0 py-2">Country</p>
            <v-text-field
              @keyup.enter="validateRegisterCountryl()"
              :rules="countryRules"
              dense
              hide-details
              v-model="submitForm.country"
              prepend-inner-icon="mdi-account"
              outlined
              placeholder="country"
            ></v-text-field>
            <!-- <p class="pb-0 mb-0 pt-2">Country</p>
            <v-select :rules="countryRules"  hide-details prepend-inner-icon="mdi-map-marker" v-model="submitForm.country" :items="country" placeholder="Please select your country" outlined></v-select> -->
          </v-form>
        </div>
        <v-col cols="12" class="pt-0 mr-2">
          <v-btn
            block
            :disabled="!valid"
            color="primary"
            @click="validateRegisterEmail()"
            >Create a new account</v-btn
          >
        </v-col>
        <p class="text-center">
          <span style="color: #7b7b69">Already have an account </span>
          <span
            @click="changeToLogin()"
            style="font-weight: bold; cursor: pointer; padding-left: 5px"
          >
            Login</span
          >
        </p>
        <v-divider></v-divider>
        <v-col cols="12">
          <p>
            To enroll you must create a new account by signing up, or sign in
            with an existing account as registered in the
            <a href="https://follow-up.no/en/" target="_blank"> Follow-up</a>
            system.
          </p>
          <p style="font-size: 12px">
            Data processing: We save your e-mail adress and your course
            participation and progress on our servers. All your data, including
            password, is stored on our servers in an encrypted format. We do not
            sell data to third party.
          </p>
          <p style="font-size: 12px">
            Prios Kompetanse AS is responsible for saving and processing your
            data.
          </p>
        </v-col>
      </template>

      <!-- Login -->
      <template v-else>
        <p class="text-center title">Login</p>
        <v-divider></v-divider>
        <div class="pa-3">
          <v-form ref="form" class="pt-3 pb-1" v-model="valid">
            <p class="pb-0 mb-0 py-2">Email</p>
            <v-text-field
              @keyup.enter="onLogin()"
              :rules="emailRules"
              dense
              hide-details
              v-model="submitForm.email"
              prepend-inner-icon="mdi-email"
              outlined
              placeholder="Email"
            ></v-text-field>
            <p class="pb-0 mb-0 py-2">Password</p>
            <v-text-field
              @keyup.enter="onLogin()"
              type="password"
              :rules="passwordRules"
              dense
              hide-details
              v-model="submitForm.password"
              prepend-inner-icon="mdi-lock"
              outlined
              placeholder="Password"
            ></v-text-field>
          </v-form>
        </div>

        <p class="mb-3 mt-4"></p>
        <v-col cols="12" class="pt-0 mr-2">
          <v-btn block :disabled="!valid" color="primary" @click="onLogin()"
            >Sign in</v-btn
          >
        </v-col>
        <p class="text-center mt-4">
          <span style="color: #7b7b69">Dont have an account</span>
          <span
            @click="changeToRegister()"
            style="font-weight: bold; cursor: pointer"
            class="ml-5"
            >Register here</span
          >
        </p>
      </template>
    </v-card>

    <!-- Wrong Username / Password -->
    <v-snackbar
      v-model="incorrectLoginSnackbar"
      :timeout="timeout"
      color="blue-grey"
      rounded="pill"
      top
      class="pt-0"
    >
      <p class="ma-0 pa-0 text-center">
        An user with this Email / Password combination are not found.
      </p>
    </v-snackbar>

    <!-- Email already taken -->
    <v-snackbar
      v-model="takenEmailSnackbar"
      :timeout="timeout"
      color="blue-grey"
      rounded="pill"
      top
      class="pt-0"
    >
      <p class="ma-0 pa-0 text-center">The email are currently in use.</p>
    </v-snackbar>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      enrollDialog: false,
      onEnroll: true,
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      incorrectLoginSnackbar: false,
      takenEmailSnackbar: false,
      timeout: 2000,
      // * Form to send up || Login from *
      submitForm: {
        fname: "",
        lname: "",
        email: "",
        password: "",
        country: "",
      },
      // * Validation toggle *
      valid: false,

      // * Form Ruleset *
      firstNameRules: [(content) => !!content || "First name cannot be empty"],
      lastNameRules: [(content) => !!content || "Last name cannot be empty"],
      passwordRules: [(content) => !!content || "Password cannot be blank"],
      countrydRules: [(content) => !!content || "Password cannot be blank"],
      countryRules: [(content) => !!content || "Need to select a country"],
      emailRules: [
        (content) => !!content || "E-mail cannot be blank",
        (content) =>
          /.+@.+\..+/.test(content) ||
          "Please make sure the email is in the correct format",
      ],
    };
  },
  methods: {
    // Open the Dialog
    openDialog(fromNavbar) {
      this.enrollDialog = true;
      if (fromNavbar == true) {
        this.onEnroll = false;
      } else {
        this.onEnroll = true;
      }
    },
    // Close the Dialog
    closeDialog() {
      this.enrollDialog = false;
    },
    // Change to Login section of the Dialog
    changeToLogin() {
      this.onEnroll = false;
    },
    changeToRegister() {
      this.onEnroll = true;
    },
    /* 
      ! * Email Validation Check
      * Register: Validation of email exists or not *
      * Contains: 
        * Network Error Dialog
        * Duplicate Email Dialog
        * Nested Create User Function
    */
    validateRegisterEmail() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/user?mode=checkuseremail&email=${this.submitForm.email}`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((Response) => {
          if (Response.data.length != 0) {
            console.log("Email Exists");
            this.emailAreInUse();
          } else {
            console.log("Email do not exist");
            this.createUser();
          }
        })
        .catch((error) => {
          /* Network Error */
          console.log(error.message);
          this.registrationErrorMessage = error.message;
        });
    },

    createUser() {
      let isValid = this.$refs.form.validate();
      console.log(isValid);
      if (isValid == true) {
        this.$http
          .post("https://app.followup.prios.no/api/user", this.submitForm, {
            headers: { Tempaccess: this.accessKey },
          })
          .then(() => {
            console.log("User now created");
            this.changeToLogin();
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        //! Open Dialog
      }
    },

    // * Snackbar Email Taken
    emailAreInUse() {
      this.takenEmailSnackbar = true;
    },

    // * Login *
    onLogin() {
      const submittedData = {
        email: this.submitForm.email,
        password: this.submitForm.password,
      };
      let loginString = submittedData.email + "_:_" + submittedData.password;
      let encodedLogin = window.btoa(loginString);
      // * POST query
      this.$http
        .post("https://app.followup.prios.no/api/login", {
          encoded_login: encodedLogin,
        })
        .then((Response) => {
          /* Client data */
          let newUser = {
            id: Response.data.id,
            fname: Response.data.fname,
            lname: Response.data.lname,
            email: Response.data.email,
            phone: Response.data.phone,
            media_id: Response.data.media_id,
            guid: Response.data.guid,
          };
          // * LocalStorage
          localStorage.setItem("token", Response.data.token);
          localStorage.setItem("user", JSON.stringify(newUser));
          localStorage.setItem("userID", JSON.stringify(newUser.id));
          /* Reload of the page */
          location.reload();
        })
        .catch((error) => {
          /* Cant Log in */
          console.log(error.message);
          console.log(error.response.status);
          // * If Unauthorised - user not found *
          this.loginErrorMessage = error.message;
          this.incorrectLogin();
          if (error.response.status == 401) {
            // * UnAuthorised Error *
          } else {
            // * Network Error *
          }
        });
    },
  },
};
</script>
