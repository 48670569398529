<template>
  <div>
    <p>selectStudyTime {{ timeToStudy.value }}</p>
    <p>Module four Score: {{ scoreOfModule[3].value }}</p>
    <p>Total Score: {{ totalModuleScore }}</p>
    <p>At Current Module Number: {{ currentModule }}</p>

    <pre>{{ sortModuleByValue.slice(0, timeToStudy.value) }}</pre>

    <!-- Can get correct courses displayed, based upon which of the modules are selected, once setting a time. -->
    <v-row>
      <v-col
        cols="4"
        v-for="(item, index) in sortModuleByValue.slice(0, timeToStudy.value)"
        :key="index"
      >
        <v-card>
          <v-btn v-if="item.id == 11">11111</v-btn>
          <pre>{{ item }}</pre>
        </v-card>
      </v-col>
    </v-row>

    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" :step="currentModule">
          <span style="font-size: 14px">Start</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          <span style="font-size: 14px">Module 2</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          <span style="font-size: 14px">Module 3</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4">
          <span style="font-size: 14px">Module 4</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 5" step="5">
          <span style="font-size: 14px">Module 5</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 6" step="6">
          <span style="font-size: 14px">Module 6</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 7" step="7">
          <span style="font-size: 14px">Module 7</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 8" step="8">
          <span style="font-size: 14px">Module 8</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 9" step="9">
          <span style="font-size: 14px">Module 9</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 10" step="10">
          <span style="font-size: 14px">Module 10</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 11" step="11">
          <span style="font-size: 14px">Module 11</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 12" step="12">
          <span style="font-size: 14px">Module 12</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 13" step="13">
          <span style="font-size: 14px">Module 13</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 14" step="14">
          <span style="font-size: 14px">Module 14</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="15">
          <span style="font-size: 14px">Summary</span>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- 
          Will be in total 60 questions, 5 each module.
          Each next click should push all 5 question values down.
          Should be iterated, and generic. 
          Using the moduleName as an identifier? or module ID? 
            - Parameters would be moduleName and moduleQuestion 1 - 5
        -->

        <!-- Module Steps -->
        <v-stepper-content
          v-for="(item, itemIndex) in modules"
          :key="itemIndex"
          :step="item.moduleStep"
        >
          <v-card class="mb-12" color="grey lighten-3" height="1000px">
            <v-row>
              <v-col cols="7">
                <div
                  v-for="(moduleItem, moduleItemIndex) in modules"
                  :key="moduleItemIndex"
                >
                  <template v-if="moduleItem.moduleStep == item.moduleStep">
                    <v-card class="pa-3" color="grey lighten-3" flat>
                      <p class="text-center">{{ moduleItem.moduleName }}</p>

                      <!-- Question 1 -->
                      <p>{{ moduleItem.questionOneText }}</p>
                      <v-radio-group
                        v-model="moduleItem.questionOneTotalValue"
                        mandatory
                        v-if="moduleItem.questionOneText"
                      >
                        <v-radio
                          v-if="moduleItem.questionOneLabelOne"
                          :label="moduleItem.questionOneLabelOne"
                          :value="moduleItem.questionOneValueOne"
                          :key="'questionOneLabelOne'"
                          name="questionOne"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionOneLabelTwo"
                          :label="moduleItem.questionOneLabelTwo"
                          :value="moduleItem.questionOneValueTwo"
                          :key="'questionOneLabelTwo'"
                          name="questionTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionOneLabelThree"
                          :label="moduleItem.questionOneLabelThree"
                          :value="moduleItem.questionOneValueThree"
                          :key="'questionOneLabelThree'"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionOneLabelFour"
                          :label="moduleItem.questionOneLabelFour"
                          :value="moduleItem.questionOneValueFour"
                          :key="'questionOneLabelFour'"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionOneLabelFive"
                          :label="moduleItem.questionOneLabelFive"
                          :value="moduleItem.questionOneValueFive"
                          :key="'questionOneLabelFive'"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionOneLabelSix"
                          :label="moduleItem.questionOneLabelSix"
                          :value="moduleItem.questionOneValueSix"
                          :key="'questionOneLabelSix'"
                        ></v-radio>
                      </v-radio-group>

                      <!-- Question 2 -->
                      <p>{{ moduleItem.questionTwoText }}</p>
                      <v-radio-group
                        v-model="moduleItem.questionTwoTotalValue"
                        mandatory
                        v-if="moduleItem.questionTwoText"
                      >
                        <v-radio
                          v-if="moduleItem.questionTwoLabelOne"
                          :label="moduleItem.questionTwoLabelOne"
                          :value="moduleItem.questionTwoValueOne"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionTwoLabelTwo"
                          :label="moduleItem.questionTwoLabelTwo"
                          :value="moduleItem.questionTwoValueTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionTwoLabelThree"
                          :label="moduleItem.questionTwoLabelThree"
                          :value="moduleItem.questionTwoValueThree"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionTwoLabelFour"
                          :label="moduleItem.questionTwoLabelFour"
                          :value="moduleItem.questionTwoValueFour"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionTwoLabelFive"
                          :label="moduleItem.questionTwoLabelFive"
                          :value="moduleItem.questionTwoValueTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionTwoLabelSix"
                          :label="moduleItem.questionTwoLabelSix"
                          :value="moduleItem.questionTwoValueSix"
                        ></v-radio>
                      </v-radio-group>

                      <!-- Question 3 -->
                      <p>{{ moduleItem.questionThreeText }}</p>
                      <v-radio-group
                        v-model="moduleItem.questionThreeTotalValue"
                        mandatory
                        v-if="moduleItem.questionThreeText"
                      >
                        <v-radio
                          v-if="moduleItem.questionThreeLabelOne"
                          :label="moduleItem.questionThreeLabelOne"
                          :value="moduleItem.questionThreeValueOne"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionThreeLabelTwo"
                          :label="moduleItem.questionThreeLabelTwo"
                          :value="moduleItem.questionThreeValueTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionThreeLabelThree"
                          :label="moduleItem.questionThreeLabelThree"
                          :value="moduleItem.questionThreeValueThree"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionThreeLabelFour"
                          :label="moduleItem.questionThreeLabelFour"
                          :value="moduleItem.questionThreeValueFour"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionThreeLabelFive"
                          :label="moduleItem.questionThreeLabelFive"
                          :value="moduleItem.questionThreeValueFive"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionThreeLabelSix"
                          :label="moduleItem.questionThreeLabelSix"
                          :value="moduleItem.questionThreeValueSix"
                        ></v-radio>
                      </v-radio-group>

                      <!-- Question 4 -->
                      <p>{{ moduleItem.questionFourText }}</p>
                      <v-radio-group
                        v-model="moduleItem.questionFourTotalValue"
                        mandatory
                        v-if="moduleItem.questionFourText"
                      >
                        <v-radio
                          v-if="moduleItem.questionFourLabelOne"
                          :label="moduleItem.questionFourLabelOne"
                          :value="moduleItem.questionFourValueOne"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFourLabelTwo"
                          :label="moduleItem.questionFourLabelTwo"
                          :value="moduleItem.questionFourValueTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFourLabelThree"
                          :label="moduleItem.questionFourLabelThree"
                          :value="moduleItem.questionFourValueThree"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFourLabelFour"
                          :label="moduleItem.questionFourLabelFour"
                          :value="moduleItem.questionFourValueFour"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFourLabelFive"
                          :label="moduleItem.questionFourLabelFive"
                          :value="moduleItem.questionFourValueFive"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFourLabelSix"
                          :label="moduleItem.questionFourLabelSix"
                          :value="moduleItem.questionFourValueSix"
                        ></v-radio>
                      </v-radio-group>

                      <!-- Question 5 -->
                      <p>{{ moduleItem.questionFiveText }}</p>
                      <v-radio-group
                        v-model="moduleItem.questionFiveTotalValue"
                        mandatory
                        v-if="moduleItem.questionFiveText"
                      >
                        <v-radio
                          v-if="moduleItem.questionFiveLabelOne"
                          :label="moduleItem.questionFiveLabelOne"
                          :value="moduleItem.questionFiveValueOne"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFiveLabelTwo"
                          :label="moduleItem.questionFiveLabelTwo"
                          :value="moduleItem.questionFiveValueTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFiveLabelThree"
                          :label="moduleItem.questionFiveLabelThree"
                          :value="moduleItem.questionFiveValueThree"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFiveLabelFour"
                          :label="moduleItem.questionFiveLabelFour"
                          :value="moduleItem.questionFiveValueFour"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFiveLabelFive"
                          :label="moduleItem.questionFiveLabelFive"
                          :value="moduleItem.questionFiveValueFive"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionFiveLabelSix"
                          :label="moduleItem.questionFiveLabelSix"
                          :value="moduleItem.questionFiveValueSix"
                        ></v-radio>
                      </v-radio-group>

                      <!-- Question 6 -->
                      <p>{{ moduleItem.questionSixText }}</p>
                      <v-radio-group
                        v-model="moduleItem.questionSixTotalValue"
                        mandatory
                        v-if="moduleItem.questionSixText"
                      >
                        <v-radio
                          v-if="moduleItem.questionSixLabelOne"
                          :label="moduleItem.questionSixLabelOne"
                          :value="moduleItem.questionSixValueOne"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionSixLabelTwo"
                          :label="moduleItem.questionSixLabelTwo"
                          :value="moduleItem.questionSixValueTwo"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionSixLabelThree"
                          :label="moduleItem.questionSixLabelThree"
                          :value="moduleItem.questionSixValueThree"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionSixLabelFour"
                          :label="moduleItem.questionSixLabelFour"
                          :value="moduleItem.questionSixValueFour"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionSixLabelFive"
                          :label="moduleItem.questionSixLabelFive"
                          :value="moduleItem.questionSixValueFive"
                        ></v-radio>
                        <v-radio
                          v-if="moduleItem.questionSixLabelSix"
                          :label="moduleItem.questionSixLabelSix"
                          :value="moduleItem.questionSixValueSix"
                        ></v-radio>
                      </v-radio-group>
                    </v-card>
                  </template>
                  <template v-else></template>
                </div>
              </v-col>
              <v-col cols="5">
                <v-card
                  v-if="currentModule == 1"
                  flat
                  color="grey lighten-3"
                  height="100%"
                >
                  <p class="text-center title">Tip step 1</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 2"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 2</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 3"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 3</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 4"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 4</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 5"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 5</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 6"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 6</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 7"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 7</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 8"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 8</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 9"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 9</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 10"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 10</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 11"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 11</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 12"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 12</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 13"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 12</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 14"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip step 12</p>
                </v-card>
                <v-card
                  v-else-if="currentModule == 15"
                  flat
                  color="grey lighten-3"
                >
                  <p class="text-center title">Tip Summary</p>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="nextStep(item.moduleStep)">
            Continue
          </v-btn>
          <v-btn
            v-if="item.moduleStep != 1"
            text
            @click="previousStep(item.moduleStep)"
          >
            Back
          </v-btn>
        </v-stepper-content>

        <!-- Summary Step -->
        <v-stepper-content step="13">
          <v-card class="mb-12" color="grey lighten-1" height="500px">
            <p>How much time are you going to put into the studyies?</p>
            <v-select
              v-model="timeToStudy"
              :items="selectStudyTime"
              item-text="time"
              item-value="value"
              label="Study time"
              return-object
              single-line
            ></v-select>
          </v-card>
          <v-btn color="primary"> Submit </v-btn>
          <v-btn text @click="previousStep(13)"> Back </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testNumber: 22,

      selectedValues: [],

      /* Todo
        Completed.
          - Implement the stepper.
          - Figure individual score.
          - Figure Total score.
          - Filter the module scores
        To Fix:
          - i18n
      */

      // Default value on how long the user are willing to study
      timeToStudy: { time: "Not going to study", value: 0 },
      // Select where user define how long he/she is willing to study
      selectStudyTime: [
        { time: "1 Hour", value: 1 },
        { time: "2 Hour", value: 2 },
        { time: "3 Hour", value: 3 },
        { time: "4 Hour", value: 4 },
      ],

      // At current Stepper level
      step: 1,
      // At current Module in the stepper
      currentModule: 1,

      // Total Score
      totalModuleScore: 0,

      // Score on each module
      scoreOfModule: [
        { id: 1, value: 0, moduleName: "Module One" },
        { id: 2, value: 0, moduleName: "Module Two" },
        { id: 3, value: 0, moduleName: "Module Three" },
        { id: 4, value: 0, moduleName: "Module Four" },
        { id: 5, value: 0, moduleName: "Module Five" },
        { id: 6, value: 0, moduleName: "Module Six" },
        { id: 7, value: 0, moduleName: "Module Seven" },
        { id: 8, value: 0, moduleName: "Module Eight" },
        { id: 9, value: 0, moduleName: "Module Nine" },
        { id: 10, value: 0, moduleName: "Module Ten" },
        { id: 11, value: 0, moduleName: "Module Eleven" },
        { id: 12, value: 0, moduleName: "Module Twelve" },
        { id: 13, value: 0, moduleName: "Module Thirteen" },
        { id: 14, value: 0, moduleName: "Module Fourteen" },
      ],

      // Stepper contents - Will change depending on TYPE of question, and how the labels change
      // Should also come from an DB or JSON file as it will be massive in the end due to individual setting on every single one. x60
      modules: [
        // Module 1
        {
          moduleStep: 1,
          moduleName: "Creativity",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleOne.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleOne.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleOne.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleOne.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleOne.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleOne.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleOne.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleOne.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleOne.questionOne.questionLabelFour"
          ),
          questionOneLabelFive: this.$t(
            "questioneerModuleOne.questionOne.questionLabelFive"
          ),
          questionOneLabelSix: this.$t(
            "questioneerModuleOne.questionOne.questionLabelSix"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleOne.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleOne.questionTwo.questionLabelTwo"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleOne.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleOne.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleOne.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleOne.questionThree.questionLabelFour"
          ),
          questionThreeLabelFive: this.$t(
            "questioneerModuleOne.questionThree.questionLabelFive"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleOne.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleOne.questionFour.questionLabelTwo"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleOne.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleOne.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleOne.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleOne.questionFive.questionLabelFour"
          ),
          questionFiveLabelFive: this.$t(
            "questioneerModuleOne.questionFive.questionLabelFive"
          ),
          // Individual value of question 1
          questionOneValueOne: 12,
          questionOneValueTwo: 22,
          questionOneValueThree: 32,
          questionOneValueFour: 42,
          questionOneValueFive: 52,
          questionOneValueSix: 60,
          // Individual value of question 2
          questionTwoValueOne: 16,
          questionTwoValueTwo: 24,
          // Individual value of question 3
          questionThreeValueOne: 12,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 32,
          questionThreeValueFour: 42,
          questionThreeValueFive: 52,
          // Individual value of question 4
          questionFourValueOne: 16,
          questionFourValueTwo: 24,
          // Individual value of question 5
          questionFiveValueOne: 13,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 32,
          questionFiveValueFour: 42,
        },
        // 2
        {
          moduleStep: 2,
          moduleName: "Yups",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleTwo.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleTwo.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleTwo.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleTwo.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleTwo.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleTwo.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleOne.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleOne.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleOne.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleTwo.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleTwo.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleTwo.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleTwo.questionTwo.questionLabelFour"
          ),
          questionTwoLabelFive: this.$t(
            "questioneerModuleTwo.questionTwo.questionLabelFive"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleTwo.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleTwo.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleTwo.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleTwo.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleTwo.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleTwo.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleTwo.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleTwo.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleTwo.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleTwo.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleTwo.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleTwo.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 13,
          questionOneValueTwo: 21,
          questionOneValueThree: 34,
          questionOneValueFour: 42,
          // Individual value of question 2
          questionTwoValueOne: 12,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 34,
          questionTwoValueFour: 41,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 33,
          questionThreeValueFour: 44,
          // Individual value of question 4
          questionFourValueOne: 12,
          questionFourValueTwo: 21,
          questionFourValueThree: 34,
          questionFourValueFour: 43,
          // Individual value of question 5
          questionFiveValueOne: 11,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 34,
          questionFiveValueFour: 42,
        },
        // 3
        {
          moduleStep: 3,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleThree.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleThree.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleThree.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleThree.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleThree.questionFive.mainQuestionText"
          ),
          questionSixText: this.$t(
            "questioneerModuleThree.questionSix.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          questionSixTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleThree.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleThree.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: "",
          questionOneLabelFour: "",
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleThree.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleThree.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleThree.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleThree.questionTwo.questionLabelFour"
          ),
          questionTwoLabelFive: this.$t(
            "questioneerModuleThree.questionTwo.questionLabelFive"
          ),
          questionTwoLabelSix: this.$t(
            "questioneerModuleThree.questionTwo.questionLabelSix"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleThree.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleThree.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: "",
          questionThreeLabelFour: "",
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleThree.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleThree.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleThree.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: "",
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleThree.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleThree.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: "",
          questionFiveLabelFour: "",
          //  Question Labels 6
          questionSixLabelOne: this.$t(
            "questioneerModuleThree.questionSix.questionLabelOne"
          ),
          questionSixLabelTwo: this.$t(
            "questioneerModuleThree.questionSix.questionLabelTwo"
          ),
          questionSixLabelThree: this.$t(
            "questioneerModuleThree.questionSix.questionLabelThree"
          ),
          questionSixLabelFour: this.$t(
            "questioneerModuleThree.questionSix.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 16,
          questionOneValueTwo: 24,
          questionOneValueThree: 0,
          questionOneValueFour: 0,
          // Individual value of question 2
          questionTwoValueOne: 12,
          questionTwoValueTwo: 22,
          questionTwoValueThree: 32,
          questionTwoValueFour: 42,
          questionTwoValueFive: 52,
          questionTwoValueSix: 60,
          // Individual value of question 3
          questionThreeValueOne: 16,
          questionThreeValueTwo: 24,
          questionThreeValueThree: 0,
          questionThreeValueFour: 0,
          // Individual value of question 4
          questionFourValueOne: 14,
          questionFourValueTwo: 23,
          questionFourValueThree: 33,
          questionFourValueFour: 0,
          // Individual value of question 5
          questionFiveValueOne: 16,
          questionFiveValueTwo: 24,
          questionFiveValueThree: 0,
          questionFiveValueFour: 0,
          // Individual value of question 6
          questionSixValueOne: 13,
          questionSixValueTwo: 23,
          questionSixValueThree: 33,
          questionSixValueFour: 41,
        },
        // 4
        {
          moduleStep: 4,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleFour.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleFour.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleFour.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleFour.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleFour.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleFour.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleFour.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleFour.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleFour.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleFour.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleFour.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleFour.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleFour.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleFour.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleFour.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleFour.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleFour.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleFour.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleFour.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleFour.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleFour.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleFour.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleFour.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleFour.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleFour.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 22,
          questionOneValueThree: 31,
          questionOneValueFour: 43,
          // Individual value of question 2
          questionTwoValueOne: 12,
          questionTwoValueTwo: 21,
          questionTwoValueThree: 33,
          questionTwoValueFour: 44,
          // Individual value of question 3
          questionThreeValueOne: 12,
          questionThreeValueTwo: 21,
          questionThreeValueThree: 33,
          questionThreeValueFour: 44,
          // Individual value of question 4
          questionFourValueOne: 11,
          questionFourValueTwo: 22,
          questionFourValueThree: 33,
          questionFourValueFour: 44,
          // Individual value of question 5
          questionFiveValueOne: 11,
          questionFiveValueTwo: 22,
          questionFiveValueThree: 34,
          questionFiveValueFour: 43,
        },
        // 5
        {
          moduleStep: 5,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleFive.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleFive.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleFive.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleFive.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleFive.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleFive.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleFive.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleFive.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleFive.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleFive.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleFive.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleFive.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleFive.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleFive.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleFive.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleFive.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleFive.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleFive.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleFive.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleFive.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleFive.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleFive.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleFive.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleFive.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: "",
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 23,
          questionOneValueThree: 32,
          questionOneValueFour: 41,
          // Individual value of question 2
          questionTwoValueOne: 12,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 34,
          questionTwoValueFour: 41,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 24,
          questionThreeValueThree: 33,
          questionThreeValueFour: 42,
          // Individual value of question 4
          questionFourValueOne: 14,
          questionFourValueTwo: 23,
          questionFourValueThree: 32,
          questionFourValueFour: 41,
          // Individual value of question 5
          questionFiveValueOne: 14,
          questionFiveValueTwo: 22,
          questionFiveValueThree: 31,
          questionFiveValueFour: 40,
        },
        // 6
        {
          moduleStep: 6,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleSix.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleSix.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleSix.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleSix.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleSix.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleSix.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleSix.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleSix.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleSix.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleSix.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleSix.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleSix.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleSix.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleSix.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleSix.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleSix.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleSix.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleSix.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleSix.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleSix.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleSix.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleSix.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleSix.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleSix.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleSix.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 23,
          questionOneValueThree: 32,
          questionOneValueFour: 41,
          // Individual value of question 2
          questionTwoValueOne: 12,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 34,
          questionTwoValueFour: 41,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 24,
          questionThreeValueThree: 33,
          questionThreeValueFour: 42,
          // Individual value of question 4
          questionFourValueOne: 14,
          questionFourValueTwo: 23,
          questionFourValueThree: 32,
          questionFourValueFour: 41,
          // Individual value of question 5
          questionFiveValueOne: 14,
          questionFiveValueTwo: 22,
          questionFiveValueThree: 31,
          questionFiveValueFour: 43,
        },
        // 7
        {
          moduleStep: 7,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleSeven.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleSeven.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleSeven.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleSeven.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleSeven.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleSeven.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleSeven.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleSeven.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleSeven.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleSeven.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleSeven.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleSeven.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleSeven.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleSeven.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleSeven.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleSeven.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleSeven.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleSeven.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleSeven.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleSeven.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleSeven.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleSeven.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleSeven.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleSeven.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleSeven.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 13,
          questionOneValueTwo: 21,
          questionOneValueThree: 34,
          questionOneValueFour: 42,
          // Individual value of question 2
          questionTwoValueOne: 11,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 34,
          questionTwoValueFour: 42,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 33,
          questionThreeValueFour: 44,
          // Individual value of question 4
          questionFourValueOne: 12,
          questionFourValueTwo: 21,
          questionFourValueThree: 34,
          questionFourValueFour: 43,
          // Individual value of question 5
          questionFiveValueOne: 11,
          questionFiveValueTwo: 24,
          questionFiveValueThree: 32,
          questionFiveValueFour: 43,
        },
        // 8
        {
          moduleStep: 8,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleEight.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleEight.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleEight.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleEight.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleEight.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleEight.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleEight.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleEight.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleEight.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleEight.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleEight.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleEight.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleEight.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleEight.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleEight.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleEight.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleEight.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleEight.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleEight.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleEight.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleEight.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleEight.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleEight.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleEight.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleEight.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 23,
          questionOneValueThree: 32,
          questionOneValueFour: 41,
          // Individual value of question 2
          questionTwoValueOne: 14,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 32,
          questionTwoValueFour: 41,
          // Individual value of question 3
          questionThreeValueOne: 14,
          questionThreeValueTwo: 23,
          questionThreeValueThree: 32,
          questionThreeValueFour: 41,
          // Individual value of question 4
          questionFourValueOne: 14,
          questionFourValueTwo: 23,
          questionFourValueThree: 32,
          questionFourValueFour: 41,
          // Individual value of question 5
          questionFiveValueOne: 14,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 32,
          questionFiveValueFour: 41,
        },
        // 9
        {
          moduleStep: 9,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleNine.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleNine.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleNine.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleNine.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleNine.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleNine.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleNine.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleNine.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleNine.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleNine.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleNine.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleNine.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleNine.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleNine.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleNine.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleNine.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleNine.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleNine.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleNine.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleNine.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleNine.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleNine.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleNine.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleNine.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleNine.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 21,
          questionOneValueThree: 33,
          questionOneValueFour: 42,
          // Individual value of question 2
          questionTwoValueOne: 14,
          questionTwoValueTwo: 21,
          questionTwoValueThree: 32,
          questionTwoValueFour: 43,
          // Individual value of question 3
          questionThreeValueOne: 14,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 33,
          questionThreeValueFour: 41,
          // Individual value of question 4
          questionFourValueOne: 11,
          questionFourValueTwo: 24,
          questionFourValueThree: 32,
          questionFourValueFour: 43,
          // Individual value of question 5
          questionFiveValueOne: 11,
          questionFiveValueTwo: 22,
          questionFiveValueThree: 34,
          questionFiveValueFour: 43,
        },
        // 10
        {
          moduleStep: 10,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleTen.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleTen.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleTen.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleTen.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleTen.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleTen.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleTen.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleTen.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleTen.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleTen.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleTen.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleTen.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleTen.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleTen.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleTen.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleTen.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleTen.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleTen.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleTen.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleTen.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleTen.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleTen.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleTen.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleTen.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleTen.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 23,
          questionOneValueThree: 32,
          questionOneValueFour: 41,
          // Individual value of question 2
          questionTwoValueOne: 11,
          questionTwoValueTwo: 24,
          questionTwoValueThree: 32,
          questionTwoValueFour: 43,
          // Individual value of question 3
          questionThreeValueOne: 13,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 34,
          questionThreeValueFour: 41,
          // Individual value of question 4
          questionFourValueOne: 14,
          questionFourValueTwo: 23,
          questionFourValueThree: 31,
          questionFourValueFour: 42,
          // Individual value of question 5
          questionFiveValueOne: 14,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 32,
          questionFiveValueFour: 41,
        },
        // 11
        {
          moduleStep: 11,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleEleven.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleEleven.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleEleven.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleEleven.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleEleven.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleEleven.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleEleven.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleEleven.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleEleven.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleEleven.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleEleven.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleEleven.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleEleven.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleEleven.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleEleven.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleEleven.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleEleven.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleEleven.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleEleven.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleEleven.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleEleven.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleEleven.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleEleven.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleEleven.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleEleven.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 23,
          questionOneValueThree: 32,
          questionOneValueFour: 41,
          // Individual value of question 2
          questionTwoValueOne: 14,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 32,
          questionTwoValueFour: 41,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 24,
          questionThreeValueThree: 33,
          questionThreeValueFour: 42,
          // Individual value of question 4
          questionFourValueOne: 13,
          questionFourValueTwo: 24,
          questionFourValueThree: 31,
          questionFourValueFour: 42,
          // Individual value of question 5
          questionFiveValueOne: 14,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 32,
          questionFiveValueFour: 41,
        },
        // 12
        {
          moduleStep: 12,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleTwelve.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleTwelve.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleTwelve.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleTwelve.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleTwelve.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleTwelve.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleTwelve.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleTwelve.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleTwelve.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleTwelve.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleTwelve.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleTwelve.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleTwelve.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleTwelve.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleTwelve.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleTwelve.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleTwelve.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleTwelve.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleTwelve.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleTwelve.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleTwelve.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleTwelve.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleTwelve.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleTwelve.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleTwelve.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 23,
          questionOneValueThree: 32,
          questionOneValueFour: 41,
          // Individual value of question 2
          questionTwoValueOne: 14,
          questionTwoValueTwo: 23,
          questionTwoValueThree: 31,
          questionTwoValueFour: 42,
          // Individual value of question 3
          questionThreeValueOne: 13,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 34,
          questionThreeValueFour: 41,
          // Individual value of question 4
          questionFourValueOne: 11,
          questionFourValueTwo: 24,
          questionFourValueThree: 32,
          questionFourValueFour: 43,
          // Individual value of question 5
          questionFiveValueOne: 14,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 32,
          questionFiveValueFour: 41,
        },
        // 13
        {
          moduleStep: 13,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleThirteen.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleThirteen.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleThirteen.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleThirteen.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleThirteen.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleThirteen.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleThirteen.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleThirteen.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleThirteen.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleThirteen.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleThirteen.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleThirteen.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleThirteen.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleThirteen.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleThirteen.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleThirteen.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleThirteen.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleThirteen.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleThirteen.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleThirteen.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleThirteen.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleThirteen.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleThirteen.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleThirteen.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleThirteen.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 13,
          questionOneValueTwo: 24,
          questionOneValueThree: 31,
          questionOneValueFour: 42,
          // Individual value of question 2
          questionTwoValueOne: 11,
          questionTwoValueTwo: 22,
          questionTwoValueThree: 33,
          questionTwoValueFour: 44,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 24,
          questionThreeValueThree: 33,
          questionThreeValueFour: 42,
          // Individual value of question 4
          questionFourValueOne: 11,
          questionFourValueTwo: 23,
          questionFourValueThree: 34,
          questionFourValueFour: 42,
          // Individual value of question 5
          questionFiveValueOne: 12,
          questionFiveValueTwo: 23,
          questionFiveValueThree: 34,
          questionFiveValueFour: 41,
        },
        // 14
        {
          moduleStep: 14,
          moduleName: "",
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleFourteen.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleFourteen.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleFourteen.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleFourteen.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleFourteen.questionFive.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleFourteen.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleFourteen.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleFourteen.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleFourteen.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleFourteen.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleFourteen.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleFourteen.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleFourteen.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleFourteen.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleFourteen.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleFourteen.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleFourteen.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleFourteen.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleFourteen.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleFourteen.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleFourteen.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleFourteen.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleFourteen.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleFourteen.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleFourteen.questionFive.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 14,
          questionOneValueTwo: 22,
          questionOneValueThree: 31,
          questionOneValueFour: 43,
          // Individual value of question 2
          questionTwoValueOne: 13,
          questionTwoValueTwo: 22,
          questionTwoValueThree: 31,
          questionTwoValueFour: 44,
          // Individual value of question 3
          questionThreeValueOne: 14,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 33,
          questionThreeValueFour: 41,
          // Individual value of question 4
          questionFourValueOne: 13,
          questionFourValueTwo: 24,
          questionFourValueThree: 32,
          questionFourValueFour: 41,
          // Individual value of question 5
          questionFiveValueOne: 12,
          questionFiveValueTwo: 24,
          questionFiveValueThree: 31,
          questionFiveValueFour: 43,
        },
        // 15 - SELF ASSESSMENT - I have interest in learning about:
        {
          moduleStep: 15,
          moduleName: this.$t("questioneerModuleFifteen.moduleHeader"),
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleFifteen.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleFifteen.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleFifteen.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleFifteen.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleFifteen.questionFive.mainQuestionText"
          ),
          questionSixText: this.$t(
            "questioneerModuleFifteen.questionSix.mainQuestionText"
          ),
          questionSevenText: this.$t(
            "questioneerModuleFifteen.questionSeven.mainQuestionText"
          ),
          questionEightText: this.$t(
            "questioneerModuleFifteen.questionEight.mainQuestionText"
          ),
          questionNineText: this.$t(
            "questioneerModuleFifteen.questionNine.mainQuestionText"
          ),
          questionTenText: this.$t(
            "questioneerModuleFifteen.questionTen.mainQuestionText"
          ),
          questionElevenText: this.$t(
            "questioneerModuleFifteen.questionEleven.mainQuestionText"
          ),
          questionTwelveText: this.$t(
            "questioneerModuleFifteen.questionTwelve.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          questionSixTotalValue: 0,
          questionSevenTotalValue: 0,
          questionEightTotalValue: 0,
          questionNineTotalValue: 0,
          questionTenTotalValue: 0,
          questionElevenTotalValue: 0,
          questionTwelveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleFifteen.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleFifteen.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleFifteen.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleFifteen.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleFifteen.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleFifteen.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleFifteen.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleFifteen.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleFifteen.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleFifteen.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleFifteen.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleFifteen.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleFifteen.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleFifteen.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleFifteen.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleFifteen.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleFifteen.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleFifteen.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleFifteen.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleFifteen.questionFive.questionLabelFour"
          ),
          //  Question Labels 6
          questionSixLabelOne: this.$t(
            "questioneerModuleFifteen.questionSix.questionLabelOne"
          ),
          questionSixLabelTwo: this.$t(
            "questioneerModuleFifteen.questionSix.questionLabelTwo"
          ),
          questionSixLabelThree: this.$t(
            "questioneerModuleFifteen.questionSix.questionLabelThree"
          ),
          questionSixLabelFour: this.$t(
            "questioneerModuleFifteen.questionSix.questionLabelFour"
          ),
          //  Question Labels 7
          questionSevenLabelOne: this.$t(
            "questioneerModuleFifteen.questionSeven.questionLabelOne"
          ),
          questionSevenLabelTwo: this.$t(
            "questioneerModuleFifteen.questionSeven.questionLabelTwo"
          ),
          questionSevenLabelThree: this.$t(
            "questioneerModuleFifteen.questionSeven.questionLabelThree"
          ),
          questionSevenLabelFour: this.$t(
            "questioneerModuleFifteen.questionSeven.questionLabelFour"
          ),
          //  Question Labels 8
          questionEightLabelOne: this.$t(
            "questioneerModuleFifteen.questionEight.questionLabelOne"
          ),
          questionEightLabelTwo: this.$t(
            "questioneerModuleFifteen.questionEight.questionLabelTwo"
          ),
          questionEightLabelThree: this.$t(
            "questioneerModuleFifteen.questionEight.questionLabelThree"
          ),
          questionEightLabelFour: this.$t(
            "questioneerModuleFifteen.questionEight.questionLabelFour"
          ),
          //  Question Labels 9
          questionNineLabelOne: this.$t(
            "questioneerModuleFifteen.questionNine.questionLabelOne"
          ),
          questionNineLabelTwo: this.$t(
            "questioneerModuleFifteen.questionNine.questionLabelTwo"
          ),
          questionNineLabelThree: this.$t(
            "questioneerModuleFifteen.questionNine.questionLabelThree"
          ),
          questionNineLabelFour: this.$t(
            "questioneerModuleFifteen.questionNine.questionLabelFour"
          ),
          //  Question Labels 10
          questionTenLabelOne: this.$t(
            "questioneerModuleFifteen.questionTen.questionLabelOne"
          ),
          questionTenLabelTwo: this.$t(
            "questioneerModuleFifteen.questionTen.questionLabelTwo"
          ),
          questionTenLabelThree: this.$t(
            "questioneerModuleFifteen.questionTen.questionLabelThree"
          ),
          questionTenLabelFour: this.$t(
            "questioneerModuleFifteen.questionTen.questionLabelFour"
          ),
          //  Question Labels 11
          questionElevenLabelOne: this.$t(
            "questioneerModuleFifteen.questionEleven.questionLabelOne"
          ),
          questionElevenLabelTwo: this.$t(
            "questioneerModuleFifteen.questionEleven.questionLabelTwo"
          ),
          questionElevenLabelThree: this.$t(
            "questioneerModuleFifteen.questionEleven.questionLabelThree"
          ),
          questionElevenLabelFour: this.$t(
            "questioneerModuleFifteen.questionEleven.questionLabelFour"
          ),
          //  Question Labels 12
          questionTwelveLabelOne: this.$t(
            "questioneerModuleFifteen.questionTwelve.questionLabelOne"
          ),
          questionTwelveLabelTwo: this.$t(
            "questioneerModuleFifteen.questionTwelve.questionLabelTwo"
          ),
          questionTwelveLabelThree: this.$t(
            "questioneerModuleFifteen.questionTwelve.questionLabelThree"
          ),
          questionTwelveLabelFour: this.$t(
            "questioneerModuleFifteen.questionTwelve.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 11,
          questionOneValueTwo: 22,
          questionOneValueThree: 33,
          questionOneValueFour: 44,
          // Individual value of question 2
          questionTwoValueOne: 11,
          questionTwoValueTwo: 22,
          questionTwoValueThree: 33,
          questionTwoValueFour: 44,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 33,
          questionThreeValueFour: 44,
          // Individual value of question 4
          questionFourValueOne: 11,
          questionFourValueTwo: 22,
          questionFourValueThree: 33,
          questionFourValueFour: 44,
          // Individual value of question 5
          questionFiveValueOne: 11,
          questionFiveValueTwo: 22,
          questionFiveValueThree: 33,
          questionFiveValueFour: 44,
          // Individual value of question 6
          questionSixValueOne: 11,
          questionSixValueTwo: 22,
          questionSixValueThree: 33,
          questionSixValueFour: 44,
          // Individual value of question 7
          questionSevenValueOne: 11,
          questionSevenValueTwo: 22,
          questionSevenValueThree: 33,
          questionSevenValueFour: 44,
          // Individual value of question 8
          questionEightValueOne: 11,
          questionEightValueTwo: 22,
          questionEightValueThree: 33,
          questionEightValueFour: 44,
          // Individual value of question 9
          questionNineValueOne: 11,
          questionNineValueTwo: 22,
          questionNineValueThree: 33,
          questionNineValueFour: 44,
          // Individual value of question 10
          questionTenValueOne: 11,
          questionTenValueTwo: 22,
          questionTenValueThree: 33,
          questionTenValueFour: 44,
          // Individual value of question 11
          questionElevenValueOne: 11,
          questionElevenValueTwo: 22,
          questionElevenValueThree: 33,
          questionElevenValueFour: 44,
          // Individual value of question 12
          questionTwelveValueOne: 11,
          questionTwelveValueTwo: 22,
          questionTwelveValueThree: 33,
          questionTwelveValueFour: 44,
        },
        // 16 - SELF ASSESSMENT - I think that I need to learn about:
        {
          moduleStep: 16,
          moduleName: this.$t("questioneerModuleSixteen.moduleHeader"),
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleSixteen.questionOne.mainQuestionText"
          ),
          questionTwoText: this.$t(
            "questioneerModuleSixteen.questionTwo.mainQuestionText"
          ),
          questionThreeText: this.$t(
            "questioneerModuleSixteen.questionThree.mainQuestionText"
          ),
          questionFourText: this.$t(
            "questioneerModuleSixteen.questionFour.mainQuestionText"
          ),
          questionFiveText: this.$t(
            "questioneerModuleSixteen.questionFive.mainQuestionText"
          ),
          questionSixText: this.$t(
            "questioneerModuleSixteen.questionSix.mainQuestionText"
          ),
          questionSevenText: this.$t(
            "questioneerModuleSixteen.questionSeven.mainQuestionText"
          ),
          questionEightText: this.$t(
            "questioneerModuleSixteen.questionEight.mainQuestionText"
          ),
          questionNineText: this.$t(
            "questioneerModuleSixteen.questionNine.mainQuestionText"
          ),
          questionTenText: this.$t(
            "questioneerModuleSixteen.questionTen.mainQuestionText"
          ),
          questionElevenText: this.$t(
            "questioneerModuleSixteen.questionEleven.mainQuestionText"
          ),
          questionTwelveText: this.$t(
            "questioneerModuleSixteen.questionTwelve.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          questionTwoTotalValue: 0,
          questionThreeTotalValue: 0,
          questionFourTotalValue: 0,
          questionFiveTotalValue: 0,
          questionSixTotalValue: 0,
          questionSevenTotalValue: 0,
          questionEightTotalValue: 0,
          questionNineTotalValue: 0,
          questionTenTotalValue: 0,
          questionElevenTotalValue: 0,
          questionTwelveTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleSixteen.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleSixteen.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleSixteen.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleSixteen.questionOne.questionLabelFour"
          ),
          // Question Labels 2
          questionTwoLabelOne: this.$t(
            "questioneerModuleSixteen.questionTwo.questionLabelOne"
          ),
          questionTwoLabelTwo: this.$t(
            "questioneerModuleSixteen.questionTwo.questionLabelTwo"
          ),
          questionTwoLabelThree: this.$t(
            "questioneerModuleSixteen.questionTwo.questionLabelThree"
          ),
          questionTwoLabelFour: this.$t(
            "questioneerModuleSixteen.questionTwo.questionLabelFour"
          ),
          //  Question Labels 3
          questionThreeLabelOne: this.$t(
            "questioneerModuleSixteen.questionThree.questionLabelOne"
          ),
          questionThreeLabelTwo: this.$t(
            "questioneerModuleSixteen.questionThree.questionLabelTwo"
          ),
          questionThreeLabelThree: this.$t(
            "questioneerModuleSixteen.questionThree.questionLabelThree"
          ),
          questionThreeLabelFour: this.$t(
            "questioneerModuleSixteen.questionThree.questionLabelFour"
          ),
          //  Question Labels 4
          questionFourLabelOne: this.$t(
            "questioneerModuleSixteen.questionFour.questionLabelOne"
          ),
          questionFourLabelTwo: this.$t(
            "questioneerModuleSixteen.questionFour.questionLabelTwo"
          ),
          questionFourLabelThree: this.$t(
            "questioneerModuleSixteen.questionFour.questionLabelThree"
          ),
          questionFourLabelFour: this.$t(
            "questioneerModuleSixteen.questionFour.questionLabelFour"
          ),
          //  Question Labels 5
          questionFiveLabelOne: this.$t(
            "questioneerModuleSixteen.questionFive.questionLabelOne"
          ),
          questionFiveLabelTwo: this.$t(
            "questioneerModuleSixteen.questionFive.questionLabelTwo"
          ),
          questionFiveLabelThree: this.$t(
            "questioneerModuleSixteen.questionFive.questionLabelThree"
          ),
          questionFiveLabelFour: this.$t(
            "questioneerModuleSixteen.questionFive.questionLabelFour"
          ),
          //  Question Labels 6
          questionSixLabelOne: this.$t(
            "questioneerModuleSixteen.questionSix.questionLabelOne"
          ),
          questionSixLabelTwo: this.$t(
            "questioneerModuleSixteen.questionSix.questionLabelTwo"
          ),
          questionSixLabelThree: this.$t(
            "questioneerModuleSixteen.questionSix.questionLabelThree"
          ),
          questionSixLabelFour: this.$t(
            "questioneerModuleSixteen.questionSix.questionLabelFour"
          ),
          //  Question Labels 7
          questionSevenLabelOne: this.$t(
            "questioneerModuleSixteen.questionSeven.questionLabelOne"
          ),
          questionSevenLabelTwo: this.$t(
            "questioneerModuleSixteen.questionSeven.questionLabelTwo"
          ),
          questionSevenLabelThree: this.$t(
            "questioneerModuleSixteen.questionSeven.questionLabelThree"
          ),
          questionSevenLabelFour: this.$t(
            "questioneerModuleSixteen.questionSeven.questionLabelFour"
          ),
          //  Question Labels 8
          questionEightLabelOne: this.$t(
            "questioneerModuleSixteen.questionEight.questionLabelOne"
          ),
          questionEightLabelTwo: this.$t(
            "questioneerModuleSixteen.questionEight.questionLabelTwo"
          ),
          questionEightLabelThree: this.$t(
            "questioneerModuleSixteen.questionEight.questionLabelThree"
          ),
          questionEightLabelFour: this.$t(
            "questioneerModuleSixteen.questionEight.questionLabelFour"
          ),
          //  Question Labels 9
          questionNineLabelOne: this.$t(
            "questioneerModuleSixteen.questionNine.questionLabelOne"
          ),
          questionNineLabelTwo: this.$t(
            "questioneerModuleSixteen.questionNine.questionLabelTwo"
          ),
          questionNineLabelThree: this.$t(
            "questioneerModuleSixteen.questionNine.questionLabelThree"
          ),
          questionNineLabelFour: this.$t(
            "questioneerModuleSixteen.questionNine.questionLabelFour"
          ),
          //  Question Labels 10
          questionTenLabelOne: this.$t(
            "questioneerModuleSixteen.questionTen.questionLabelOne"
          ),
          questionTenLabelTwo: this.$t(
            "questioneerModuleSixteen.questionTen.questionLabelTwo"
          ),
          questionTenLabelThree: this.$t(
            "questioneerModuleSixteen.questionTen.questionLabelThree"
          ),
          questionTenLabelFour: this.$t(
            "questioneerModuleSixteen.questionTen.questionLabelFour"
          ),
          //  Question Labels 11
          questionElevenLabelOne: this.$t(
            "questioneerModuleSixteen.questionEleven.questionLabelOne"
          ),
          questionElevenLabelTwo: this.$t(
            "questioneerModuleSixteen.questionEleven.questionLabelTwo"
          ),
          questionElevenLabelThree: this.$t(
            "questioneerModuleSixteen.questionEleven.questionLabelThree"
          ),
          questionElevenLabelFour: this.$t(
            "questioneerModuleSixteen.questionEleven.questionLabelFour"
          ),
          //  Question Labels 12
          questionTwelveLabelOne: this.$t(
            "questioneerModuleSixteen.questionTwelve.questionLabelOne"
          ),
          questionTwelveLabelTwo: this.$t(
            "questioneerModuleSixteen.questionTwelve.questionLabelTwo"
          ),
          questionTwelveLabelThree: this.$t(
            "questioneerModuleSixteen.questionTwelve.questionLabelThree"
          ),
          questionTwelveLabelFour: this.$t(
            "questioneerModuleSixteen.questionTwelve.questionLabelFour"
          ),
          // Individual value of question 1
          questionOneValueOne: 11,
          questionOneValueTwo: 22,
          questionOneValueThree: 33,
          questionOneValueFour: 44,
          // Individual value of question 2
          questionTwoValueOne: 11,
          questionTwoValueTwo: 22,
          questionTwoValueThree: 33,
          questionTwoValueFour: 44,
          // Individual value of question 3
          questionThreeValueOne: 11,
          questionThreeValueTwo: 22,
          questionThreeValueThree: 33,
          questionThreeValueFour: 44,
          // Individual value of question 4
          questionFourValueOne: 11,
          questionFourValueTwo: 22,
          questionFourValueThree: 33,
          questionFourValueFour: 44,
          // Individual value of question 5
          questionFiveValueOne: 11,
          questionFiveValueTwo: 22,
          questionFiveValueThree: 33,
          questionFiveValueFour: 44,
          // Individual value of question 6
          questionSixValueOne: 11,
          questionSixValueTwo: 22,
          questionSixValueThree: 33,
          questionSixValueFour: 44,
          // Individual value of question 7
          questionSevenValueOne: 11,
          questionSevenValueTwo: 22,
          questionSevenValueThree: 33,
          questionSevenValueFour: 44,
          // Individual value of question 8
          questionEightValueOne: 11,
          questionEightValueTwo: 22,
          questionEightValueThree: 33,
          questionEightValueFour: 44,
          // Individual value of question 9
          questionNineValueOne: 11,
          questionNineValueTwo: 22,
          questionNineValueThree: 33,
          questionNineValueFour: 44,
          // Individual value of question 10
          questionTenValueOne: 11,
          questionTenValueTwo: 22,
          questionTenValueThree: 33,
          questionTenValueFour: 44,
          // Individual value of question 11
          questionElevenValueOne: 11,
          questionElevenValueTwo: 22,
          questionElevenValueThree: 33,
          questionElevenValueFour: 44,
          // Individual value of question 12
          questionTwelveValueOne: 11,
          questionTwelveValueTwo: 22,
          questionTwelveValueThree: 33,
          questionTwelveValueFour: 44,
        },
        // 17 - Hours
        {
          moduleStep: 17,
          moduleName: this.$t("questioneerModuleSeventeen.moduleHeader"),
          // Main Question Text
          questionOneText: this.$t(
            "questioneerModuleSeventeen.questionOne.mainQuestionText"
          ),
          // Main score of selected question
          questionOneTotalValue: 0,
          // Question Labels 1
          questionOneLabelOne: this.$t(
            "questioneerModuleSeventeen.questionOne.questionLabelOne"
          ),
          questionOneLabelTwo: this.$t(
            "questioneerModuleSeventeen.questionOne.questionLabelTwo"
          ),
          questionOneLabelThree: this.$t(
            "questioneerModuleSeventeen.questionOne.questionLabelThree"
          ),
          questionOneLabelFour: this.$t(
            "questioneerModuleSeventeen.questionOne.questionLabelFour"
          ),
          questionOneLabelFive: this.$t(
            "questioneerModuleSeventeen.questionOne.questionLabelFive"
          ),
          // Individual value of question 1
          questionOneValueOne: 11,
          questionOneValueTwo: 22,
          questionOneValueThree: 33,
          questionOneValueFour: 44,
          questionOneValueFive: 55,
        },
      ],
    };
  },
  methods: {
    getOptionValue(options, value) {
      // Get the value for a selected option
      const selectedOption = options.find((option) => option.label === value);
      return selectedOption ? selectedOption.value : value;
    },
    updateSelectedValue(questionIndex, optionIndex) {
      // Update the selected value for a question
      this.$set(
        this.selectedValues,
        questionIndex,
        this.questions[questionIndex].options[optionIndex].label
      );
    },

    // Next Stepper
    nextStep(stepData) {
      let CurrentStep = stepData;
      this.step = CurrentStep + 1;
      this.currentModule = CurrentStep + 1;
      this.sumTotalModuleValue();
    },

    // Moving back a step
    previousStep(stepData) {
      let CurrentStep = stepData;
      this.step = CurrentStep - 1;
      this.currentModule = CurrentStep - 1;
      this.sumTotalModuleValue();
    },

    // Sum up the totalModuleValue, and push total value + module name as parameters into the next function (top score - 240)
    sumTotalModuleValue() {
      // Calculation for each of the module
      let totalModuleValueOne =
        Number(String(this.modules[0].questionOneTotalValue)[1]) +
        Number(String(this.modules[0].questionTwoTotalValue)[1]) +
        Number(String(this.modules[0].questionThreeTotalValue)[1]) +
        Number(String(this.modules[0].questionFourTotalValue)[1]) +
        Number(String(this.modules[0].questionFiveTotalValue)[1]);
      let totalModuleValueTwo =
        Number(String(this.modules[1].questionOneTotalValue)[1]) +
        Number(String(this.modules[1].questionTwoTotalValue)[1]) +
        Number(String(this.modules[1].questionThreeTotalValue)[1]) +
        Number(String(this.modules[1].questionFourTotalValue)[1]) +
        Number(String(this.modules[1].questionFiveTotalValue)[1]);
      let totalModuleValueThree =
        Number(String(this.modules[2].questionOneTotalValue)[1]) +
        Number(String(this.modules[2].questionTwoTotalValue)[1]) +
        Number(String(this.modules[2].questionThreeTotalValue)[1]) +
        Number(String(this.modules[2].questionFourTotalValue)[1]) +
        Number(String(this.modules[2].questionFiveTotalValue)[1]) +
        Number(String(this.modules[2].questionSixTotalValue)[1]);
      let totalModuleValueFour =
        Number(String(this.modules[3].questionOneTotalValue)[1]) +
        Number(String(this.modules[3].questionTwoTotalValue)[1]) +
        Number(String(this.modules[3].questionThreeTotalValue)[1]) +
        Number(String(this.modules[3].questionFourTotalValue)[1]) +
        Number(String(this.modules[3].questionFiveTotalValue)[1]);
      let totalModuleValueFive =
        Number(String(this.modules[4].questionOneTotalValue)[1]) +
        Number(String(this.modules[4].questionTwoTotalValue)[1]) +
        Number(String(this.modules[4].questionThreeTotalValue)[1]) +
        Number(String(this.modules[4].questionFourTotalValue)[1]) +
        Number(String(this.modules[4].questionFiveTotalValue)[1]);
      let totalModuleValueSix =
        Number(String(this.modules[5].questionOneTotalValue)[1]) +
        Number(String(this.modules[5].questionTwoTotalValue)[1]) +
        Number(String(this.modules[5].questionThreeTotalValue)[1]) +
        Number(String(this.modules[5].questionFourTotalValue)[1]) +
        Number(String(this.modules[5].questionFiveTotalValue)[1]);
      let totalModuleValueSeven =
        Number(String(this.modules[6].questionOneTotalValue)[1]) +
        Number(String(this.modules[6].questionTwoTotalValue)[1]) +
        Number(String(this.modules[6].questionThreeTotalValue)[1]) +
        Number(String(this.modules[6].questionFourTotalValue)[1]) +
        Number(String(this.modules[6].questionFiveTotalValue)[1]);
      let totalModuleValueEight =
        Number(String(this.modules[7].questionOneTotalValue)[1]) +
        Number(String(this.modules[7].questionTwoTotalValue)[1]) +
        Number(String(this.modules[7].questionThreeTotalValue)[1]) +
        Number(String(this.modules[7].questionFourTotalValue)[1]) +
        Number(String(this.modules[7].questionFiveTotalValue)[1]);
      let totalModuleValueNine =
        Number(String(this.modules[8].questionOneTotalValue)[1]) +
        Number(String(this.modules[8].questionTwoTotalValue)[1]) +
        Number(String(this.modules[8].questionThreeTotalValue)[1]) +
        Number(String(this.modules[8].questionFourTotalValue)[1]) +
        Number(String(this.modules[8].questionFiveTotalValue)[1]);
      let totalModuleValueTen =
        Number(String(this.modules[9].questionOneTotalValue)[1]) +
        Number(String(this.modules[9].questionTwoTotalValue)[1]) +
        Number(String(this.modules[9].questionThreeTotalValue)[1]) +
        Number(String(this.modules[9].questionFourTotalValue)[1]) +
        Number(String(this.modules[9].questionFiveTotalValue)[1]);
      let totalModuleValueEleven =
        Number(String(this.modules[10].questionOneTotalValue)[1]) +
        Number(String(this.modules[10].questionTwoTotalValue)[1]) +
        Number(String(this.modules[10].questionThreeTotalValue)[1]) +
        Number(String(this.modules[10].questionFourTotalValue)[1]) +
        Number(String(this.modules[10].questionFiveTotalValue)[1]);
      let totalModuleValueTwelve =
        Number(String(this.modules[11].questionOneTotalValue)[1]) +
        Number(String(this.modules[11].questionTwoTotalValue)[1]) +
        Number(String(this.modules[11].questionThreeTotalValue)[1]) +
        Number(String(this.modules[11].questionFourTotalValue)[1]) +
        Number(String(this.modules[11].questionFiveTotalValue)[1]);

      // Assigning the values to individual module
      this.scoreOfModule[0].value = totalModuleValueOne;
      this.scoreOfModule[1].value = totalModuleValueTwo;
      this.scoreOfModule[2].value = totalModuleValueThree;
      this.scoreOfModule[3].value = totalModuleValueFour;
      this.scoreOfModule[4].value = totalModuleValueFive;
      this.scoreOfModule[5].value = totalModuleValueSix;
      this.scoreOfModule[6].value = totalModuleValueSeven;
      this.scoreOfModule[7].value = totalModuleValueEight;
      this.scoreOfModule[8].value = totalModuleValueNine;
      this.scoreOfModule[9].value = totalModuleValueTen;
      this.scoreOfModule[10].value = totalModuleValueEleven;
      this.scoreOfModule[11].value = totalModuleValueTwelve;

      // Calculate Total Score - Breaking down the amount into 3 parts.
      let totalModuleScoreOne =
        totalModuleValueOne +
        totalModuleValueTwo +
        totalModuleValueThree +
        totalModuleValueFour;
      let totalModuleScoreTwo =
        totalModuleValueFive +
        totalModuleValueSix +
        totalModuleValueSeven +
        totalModuleValueEight;
      let totalModuleScoreThree =
        totalModuleValueNine +
        totalModuleValueTen +
        totalModuleValueEleven +
        totalModuleValueTwelve;

      // Total Score
      let totalModuleScore =
        totalModuleScoreOne + totalModuleScoreTwo + totalModuleScoreThree;
      // Push to total score variable
      this.totalModuleScore = totalModuleScore;
    },
  },
  computed: {
    // Goes through every value in the stepper above, and filter through it.  - Displaying the ones with the lowest value (5) first, and highest value (20) last
    sortModuleByValue() {
      return this.scoreOfModule.slice().sort(function (a, b) {
        return a.value > b.value ? 1 : -1;
      });
    },
  },
};
</script>

<style scoped>
.v-stepper_header {
  overflow: auto;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: left;
}
</style>
