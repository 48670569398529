<template>
  <v-footer color="#FFFFFF" elevation="10">
    <v-row no-gutters>
      <v-col cols="12">
        <v-divider class="dividerStyling"></v-divider>
      </v-col>
      <!-- Erasmus Logo -->
      <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" v-if="$vuetify.breakpoint.mdAndDown">
        <v-img src="img/erasmusLogoFooter.jpg" contain max-width="300"></v-img>
      </v-col>
      <v-col v-else cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <v-img src="img/erasmusLogoFooter.jpg" contain></v-img>
      </v-col> -->
      <!-- EU Logo -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <!-- <v-img
          src="@/assets/images/EUNEWLOGO.jpg"
          contain
          max-width="300"
        ></v-img> -->
      </v-col>
      <v-col v-else cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <!-- <v-img
          src="@/assets/images/EUNEWLOGO.jpg"
          contain
          height="100"
          width="300px"
        ></v-img> -->
      </v-col>
      <!-- Text -->
      <!-- <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-0 pt-3" v-if="$vuetify.breakpoint.mdAndDown">
        <p class="footerText">Smart Business for All - 2021-1-NO01-KA220-VET-000034660</p>
        <p class="footerText">{{ $t('footer.footer contact info headliner') }}</p>
      </v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-15 pt-3" v-else>
        <p class="footerText">Smart Business for All - 2021-1-NO01-KA220-VET-000034660</p>
        <p class="footerText">{{ $t('footer.footer contact info headliner') }}</p>
      </v-col> -->

      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-15 pt-3">
        <p class="footerText">
          <!-- Smart Business for All - 2021-1-NO01-KA220-VET-000034660 -->
          {{ $t("footer.footer title") }}
        </p>
        <a
          href="https://www.facebook.com/profile.php?id=100083213684739"
          target="_blank"
        >
          <v-icon large>mdi-facebook</v-icon>
        </a>
        <a href="https://www.instagram.com/smartbusiness4all/" target="_blank">
          <v-icon class="pl-5" large style="cursor: pointer"
            >mdi-instagram</v-icon
          >
        </a>
      </v-col>

      <!-- Image -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <v-img
          src="img/smab/SMAB_New_logo.png"
          max-height="130"
          max-width="130"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.cursorStyle {
  cursor: pointer;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: black;
}

.borderTop {
  border-top: red solid 2px;
  box-shadow: 0px 2px 12px #00000033;
}

.dividerStyling {
  background-color: #004aad;
  border-radius: 4px;
  opacity: 1;
  padding: 2px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footerText {
  font-family: "Lato", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #6a6a6a;
  opacity: 1;
  font-size: 14px;
}
</style>
