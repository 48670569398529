<template>
  <v-dialog v-model="updatedTemplateDialog" fullscreen>
    <v-card class="pa-5">
      <!-- Navigation Global -->
      <v-card-title class="mb-0 pb-0">
        <!-- <pre>{{dataFromParent}}</pre> -->
        <span class="headline">{{ dataFromParent.name }}</span>
        <v-spacer />
        <v-btn color="error" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="12">
        <v-img src="img/lichenLogo.jpg" contain height="70"></v-img>
      </v-col>
      <v-divider class="mb-5"></v-divider>

      <!-- Template 1 - Landing Page -->
      <template v-if="displayTemplate == 1">
        <!-- Course Information -->
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
                class="pa-5"
              >
                <v-img :src="dataFromParent.background_image"></v-img>
              </v-col>
              <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
                <p>{{ dataFromParent.name }}</p>
                <p>{{ dataFromParent.description }}</p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <!-- Display the Main Courses -->
        <v-row class="ma-0 pa-0 pt-4">
          <!-- <pre>{{subCoursesInCourse}}</pre> -->
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            xs="12"
            v-for="(subCourse, subCourseIndex) in subCoursesInCourse"
            :key="subCourseIndex"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="pa-2"
                :elevation="hover ? 12 : 2"
                @click="getSubcourseData(subCourse)"
              >
                <v-img :src="subCourse.content_background" width="90%"></v-img>
                <p class="title">
                  {{ subCourse.content_label
                  }}<v-icon
                    v-if="checkActivityProgress(subCourse.id, null)"
                    color="success"
                    >mdi-check</v-icon
                  >
                </p>
                <p>{{ subCourse.content_description }}</p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>

      <!-- Clicked one of the sub courses, display its content pages -->
      <template v-else-if="displayTemplate == 2">
        <!-- Navigation -->
        <v-row class="ma-0 pa-0">
          <v-btn
            x-large
            icon
            title="Go back to Main Course"
            @click="goBackToRootCourse()"
            v-if="singularCourse == false"
          >
            <v-icon x-large color="primary">mdi-home</v-icon>
          </v-btn>
        </v-row>

        <!-- Subcourse Frontpage -->
        <v-row class="ma-0 pa-0">
          <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
            <v-img
              v-if="singularCourse == true"
              :src="dataFromParent.background_image"
              width="400px"
            ></v-img>
            <v-img
              v-else
              :src="subCourseDataParent.content_background"
              width="400px"
            ></v-img>
          </v-col>
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12" class="pt-15">
            <p v-if="singularCourse == true" class="title">
              {{ dataFromParent.name }}
            </p>
            <p v-else class="title">{{ subCourseDataParent.content_label }}</p>
            <p v-if="singularCourse == true">
              {{ dataFromParent.description }}
            </p>
            <p v-else>{{ subCourseDataParent.content_description }}</p>
            <!-- <v-card class="pa-2">
              <v-progress-linear class="pl-5" color="light-blue lighten-2" height="30" :value="setProgressScore">
                <span style="font-weight: bold; font-size: 32px;">{{setProgressScore}} % </span>
              </v-progress-linear>
            </v-card> -->
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-divider></v-divider>

        <!-- SubCourse Contents -->
        <v-row class="ma-0 pa-0 pt-4">
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            xs="12"
            v-for="(subCourseContents, subCourseContentsIndex) in subCourseData"
            :key="subCourseContentsIndex"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="pa-5"
                height="100%"
                :elevation="hover ? 12 : 2"
                @click="
                  getSubCourseContent(subCourseContents.id, subCourseContents)
                "
                :color="
                  dataFromParent.language == 'Bulgarian' ? '#FFCC80' : '#C8E6C9'
                "
              >
                <p class="text-center">
                  {{ subCourseContents.label
                  }}<v-icon
                    v-if="
                      checkActivityProgress(
                        subCourseDataParent.id,
                        subCourseContents.id
                      )
                    "
                    color="success"
                    >mdi-check</v-icon
                  >
                </p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>

      <!-- Clicked one of lessons of the sub course -->
      <template v-else-if="displayTemplate == 3">
        <span
          class="ml-6 pt-0 mt-0"
          style="border-bottom: solid lightgrey 2px"
          >{{ currentLessonLabel }}</span
        >
        <!-- Navigation -->
        <template>
          <v-card
            class="ml-5 pt-5"
            flat
            style="
              position: sticky;
              bottom: 0;
              right: 0;
              position: -webkit-sticky;
            "
          >
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <span
                  v-if="singularCourse == false"
                  style="
                    font-size: 11px;
                    border-bottom: 1px solid gray;
                    cursor: pointer;
                  "
                  @click="goBackToRootCourse()"
                  >Go back to main course</span
                >
                <v-btn
                  v-if="singularCourse == false"
                  x-large
                  icon
                  title="Go back to Main Course"
                  @click="goBackToRootCourse()"
                >
                  <v-icon x-large color="primary">mdi-home</v-icon>
                </v-btn>
                <v-btn
                  x-large
                  icon
                  title="Go back to Chapter Overview"
                  @click="goBackToSubCourse()"
                >
                  <v-icon x-large color="success">mdi-arrow-left-bold</v-icon>
                </v-btn>
                <span
                  style="
                    font-size: 11px;
                    border-bottom: 1px solid gray;
                    cursor: pointer;
                  "
                  @click="goBackToSubCourse()"
                  >Go back to Chapter Overview</span
                >
              </v-col>
              <v-col cols="12" class="ma-0 pa-0 pl-5">
                <span
                  style="
                    font-size: 11px;
                    border-bottom: 1px solid gray;
                    cursor: pointer;
                  "
                  v-if="
                    this.lessonIndexStatus == 'ok' ||
                    this.lessonIndexStatus == 'end'
                  "
                  @click="goToPreviousLesson()"
                  >{{ previousLessonLabel }}</span
                >
                <v-btn
                  v-if="
                    this.lessonIndexStatus == 'ok' ||
                    this.lessonIndexStatus == 'end'
                  "
                  x-large
                  icon
                  title="Go back to Previous Chapter"
                  @click="goToPreviousLesson()"
                >
                  <v-icon x-large color="blue">mdi-arrow-left-bold</v-icon>
                </v-btn>
                <template v-if="this.onlyOneChapter == false">
                  <v-btn
                    v-if="
                      this.lessonIndexStatus == 'ok' ||
                      this.lessonIndexStatus == 'start'
                    "
                    x-large
                    icon
                    title="Go to the Next Chapter"
                    @click="goToNextLesson()"
                  >
                    <v-icon x-large color="blue">mdi-arrow-right-bold</v-icon>
                  </v-btn>
                  <span
                    style="
                      font-size: 11px;
                      border-bottom: 1px solid gray;
                      cursor: pointer;
                    "
                    v-if="
                      this.lessonIndexStatus == 'ok' ||
                      this.lessonIndexStatus == 'start'
                    "
                    @click="goToNextLesson()"
                    >{{ nextLessonLabel }}</span
                  >
                </template>
              </v-col>
            </v-row>
          </v-card>
        </template>

        <!-- Content -->
        <v-row class="ma-0 pa-0">
          <!-- Displaying content based upon type -->

          <v-col
            cols="12"
            :xl="lessonContent.class_list.split('xs')[1]"
            :lg="lessonContent.class_list.split('xs')[1]"
            :md="lessonContent.class_list.split('xs')[1]"
            sm="12"
            xs="12"
            v-for="(lessonContent, lessonContentIndex) in lessonContentData"
            :key="lessonContentIndex"
          >
            <v-card height="100%" flat>
              <template v-if="lessonContent.type == 'text'">
                <p class="pa-5" v-html="lessonContent.content"></p>
              </template>
              <template v-else-if="lessonContent.type == 'task'">
                <!-- <TaskData :gettingChapterContentTask="lessonContent.content"></TaskData> -->
                <div v-if="subCourseDataParent.save_task_responses == 1">
                  <!-- <TaskDataWithResponse :gettingChapterContentTask="lessonContent.content" :coursesActivityId="userActivityId" @taskContentDone="setTaskContentDone"></TaskDataWithResponse> -->
                  <!-- <p>{{lessonContent}}</p> -->
                  <TaskData
                    :gettingChapterContentTask="lessonContent.content"
                    :nameOfModule="dataFromParent.name"
                    @taskContentDone="setTaskContentDone"
                    @goingBackToChapterOverview="goBackToSubCourse"
                  ></TaskData>
                </div>
                <div v-else>
                  <TaskData
                    :gettingChapterContentTask="lessonContent.content"
                    :nameOfModule="dataFromParent.name"
                    @taskContentDone="setTaskContentDone"
                    @goingBackToChapterOverview="goBackToSubCourse"
                  ></TaskData>
                </div>
              </template>
              <template v-else-if="lessonContent.type == 'image'">
                <!-- <v-img :src="lessonContent.content" height="300" contain @click="goToImageTemplate(lessonContent.content, 5)"></v-img> -->
                <v-img
                  :src="lessonContent.content"
                  height="300"
                  contain
                  @click="openLargerImageTemplate(lessonContent.content)"
                ></v-img>
              </template>
              <template v-else-if="lessonContent.type == 'youtube_video'">
                <iframe
                  v-if="$vuetify.breakpoint.mdAndDown"
                  :src="lessonContent.content"
                  width="100%"
                  style="min-height: 300px"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <iframe
                  v-else
                  style="margin-left: 2%"
                  width="80%"
                  height="500"
                  :src="lessonContent.content"
                  title="Digital SKills"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </template>
              <template v-else-if="lessonContent.type == 'file'">
                <div style="margin-left: calc((100% - 80%) / 2)">
                  <iframe
                    v-if="$vuetify.breakpoint.mdAndDown"
                    :src="lessonContent.content"
                    width="100%"
                    height="800px"
                    style="min-height: 300px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    v-else
                    style="margin-left: 2%"
                    width="80%"
                    height="800px"
                    :src="lessonContent.content"
                    title="Digital SKills"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </template>
              <template v-else-if="lessonContent.type == 'embededLink'">
                <div style="margin-left: calc((100% - 80%) / 2)">
                  <iframe
                    v-if="$vuetify.breakpoint.mdAndDown"
                    :src="lessonContent.content"
                    width="100%"
                    height="800px"
                    style="min-height: 300px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    v-else
                    style="margin-left: 2%"
                    width="80%"
                    height="800px"
                    :src="lessonContent.content"
                    title="Digital SKills"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </template>
              <template v-else>
                No type specificed
                {{ lessonContent }}
              </template>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!-- Non Lesson from Root (image, pds, files, youtube, iframe) - Singular item -->
      <template v-else-if="displayTemplate == 4">
        <!-- Navigation - Back to Root -->
        <v-row class="ma-0 pa-0 pb-10">
          <v-btn
            x-large
            icon
            title="Go back to Main Course"
            @click="goBackToRootCourse()"
            v-if="singularCourse == false"
          >
            <v-icon x-large color="primary">mdi-home</v-icon>
          </v-btn>
        </v-row>

        <!-- Youtube -->
        <template v-if="subCourseDataParent.type == 'youtube_video'">
          <iframe
            v-if="$vuetify.breakpoint.mdAndDown"
            :src="subCourseDataParent.content"
            width="100%"
            style="min-height: 300px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-else
            style="margin-left: 2%"
            width="80%"
            height="700"
            :src="subCourseDataParent.content"
            title="Digital SKills"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </template>
        <!-- File -->
        <template v-else-if="subCourseDataParent.type == 'files'">
          <div style="margin-left: calc((100% - 80%) / 2)">
            <iframe
              v-if="$vuetify.breakpoint.mdAndDown"
              :src="subCourseDataParent.content"
              width="100%"
              height="800px"
              style="min-height: 300px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              v-else
              style="margin-left: 2%"
              width="80%"
              height="800px"
              :src="subCourseDataParent.content"
              title="Digital SKills"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </template>
        <!-- Image -->
        <template v-else-if="subCourseDataParent.type == 'image'">
          <v-img
            :src="subCourseDataParent.content"
            height="auto"
            contain
          ></v-img>
        </template>
      </template>

      <!-- Larger Image Template -->
      <template v-else-if="displayTemplate == 5">
        <v-img
          :src="largerImageData"
          height="90vh"
          width="100vw"
          contain
          @click="returnToLesson()"
        ></v-img>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import TaskData from "./taskData.vue";
// import TaskDataWithResponse from "@/components/training/taskDataWithResponse.vue";
export default {
  components: {
    TaskData,
    // TaskDataWithResponse
  },
  props: {
    userActivityId: {
      type: Number,
    },
  },
  data() {
    return {
      // Data to send to the DB
      currentDate: "",
      userID: "",
      courseID: "",
      status: 1,
      // Access Key to followup API - Hid
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      updatedTemplateDialog: false,
      dataFromParent: "",
      subCoursesInCourse: "",
      // Clicked Sub Course Data
      subCourseDataParent: "",
      subCourseData: "",
      clickedLessonID: "",
      lessonContentData: "",

      // Navigation regarding inner chapters
      previousLesson: "",
      previousLessonLabel: "",
      nextLesson: "",
      nextLessonLabel: "",
      currentLessonLabel: "",

      lessonIndexStatus: "ok",
      //
      displayTemplate: 1,

      // Larget Image
      largerImageData: "",

      // Activity id of user
      activityIdOfUser: "",
      contentData: [],
      userActivityProgress: [],
      tempCurrentContentObj: {},
      singularCourse: false,
      onlyOneChapter: false,
      lenghtOfSubCourses: "",
    };
  },
  methods: {
    // Open Dialog
    openDialog(courseDataFromParent) {
      // this.updatedTemplateDialog = true;
      this.dataFromParent = courseDataFromParent;
      this.getAllSubCourses(courseDataFromParent.id);
      setTimeout(() => {
        if (this.userActivityId) {
          this.activityIdOfUser = this.userActivityId;
          this.getUserActivityProgress(this.userActivityId);
        }
      }, 500);

      // this.getAllTheFdata();
      // this.displayTemplate = 1;
      this.addClickedCourseIDToLocalStorage(courseDataFromParent.id);
      this.getCourseID(courseDataFromParent.id);
      this.getCurrentDate();
      this.getUserIdFromLocaleStorage();
      this.getIfUserBeenHereBefore();
    },

    // getAllTheFdata() {
    //   console.log("fuck");
    // },

    getCourseID(courseID) {
      this.courseID = courseID;
      console.log("courseID", courseID);
    },

    // get current date
    getCurrentDate() {
      let currentDate = new Date().toISOString().substr(0, 10);
      this.currentDate = currentDate;
      console.log("currentDate", currentDate);
    },

    // get userID from localstorage
    getUserIdFromLocaleStorage() {
      let userId = localStorage.getItem("userID");
      this.userID = userId;
      console.log("userId", this.userID);
    },

    // Add clicked course ID to localstorage
    addClickedCourseIDToLocalStorage(courseID) {
      localStorage.setItem("clickedCourseID", courseID);
      this.courseID = courseID;
      this.getUserActivityProgress;
    },

    // Run a query to see if user clicked course before
    // getIfUserBeenHereBefore() {
    //   console.log("AM I RAN");
    //   this.$http
    //     .get(
    //       `https://app.followup.prios.no/api/courses/collection_activity?user_id=${this.userID}&course_collection_id=${this.courseID}`,
    //       { headers: { Tempaccess: this.accessKey } }
    //     )
    //     .then((response) => {
    //       console.log("response.data", response.data);
    //     });
    // },
    getIfUserBeenHereBefore() {
      console.log("AM I RAN");
      const url = `https://app.followup.prios.no/api/courses/collection_activity?user_id=${this.userID}&course_collection_id=${this.courseID}`;
      const headers = { Tempaccess: this.accessKey };

      this.$http
        .get(url, { headers })
        .then((response) => {
          // Store the response data in a variable
          const data = response.data;

          // Filter the data to find objects matching userID and courseID
          const filteredData = data.filter(
            (obj) =>
              obj.user_id == this.userID &&
              obj.course_collection_id == this.courseID
          );

          // Check if any matching objects are found
          if (filteredData.length > 0) {
            console.log("User has been here before for the specified course.");
            // Perform your logic for when a match is found
          } else {
            console.log(
              "User has not been here before for the specified course."
            );
            // Perform your logic for when no match is found
            this.postPersonWhoCompletedItToDB();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    postPersonWhoCompletedItToDB() {
      let userWhoCompletedIt = {
        user_id: this.userID,
        course_collection_id: this.courseID,
        status: 1,
        start_date: this.currentDate,
        end_date: this.currentDate,
      };

      console.log(userWhoCompletedIt);
      this.$http
        .post(
          "https://app.followup.prios.no/api/courses/collection_activity",
          userWhoCompletedIt,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then(() => {
          console.log("User has completed the module");
        });
    },

    // Get User Data from Localstorage
    getUserDataFromLocaleStorage() {
      let userData = localStorage.getItem("user");
      userData = JSON.parse(userData);
      // console.log("userData", userData.fname);
      this.userEmail = userData.email;
      this.userFirstName = userData.fname;
      this.userLastName = userData.lname;
    },

    /* Get ALL the data
      - Current Date x2
      - User ID
      - Course ID
      - status: 1,
    */

    // Close Dialog
    closeDialog() {
      this.updatedTemplateDialog = false;
    },
    // Get all the sub course (even if there is only 1)
    getAllSubCourses(courseID) {
      this.$http
        .get(
          "https://app.followup.prios.no/api/courses/collection_content?mode=getcollectioncontent&courses_collection_id=" +
            courseID,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.subCoursesInCourse = response.data;
          this.lenghtOfSubCourses = response.data.length;
        })
        .then((response) => {
          if (this.lenghtOfSubCourses == 1) {
            this.getSubcourseData(this.subCoursesInCourse[0]);
            this.updatedTemplateDialog = true;
            this.displayTemplate = 3;
            this.singularCourse = true;
          } else {
            this.updatedTemplateDialog = true;
            this.displayTemplate = 1;
            this.singularCourse = false;
          }
        });
    },

    // Get clicked Subcourse Data, based on its type
    getSubcourseData(chapterClicked) {
      this.subCourseDataParent = chapterClicked;
      let clickedSubID = chapterClicked.content;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lessons?mode=getchapters&courses_id=" +
            clickedSubID,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.subCourseData = response.data;

          if (this.subCourseData.length == 1) {
            this.onlyOneChapter = true;
          } else {
            this.onlyOneChapter = false;
          }
          if (chapterClicked.type == "lesson") {
            this.displayTemplate = 2;
          } else if (chapterClicked.type == "files") {
            this.setUserActivityProgress(
              chapterClicked.id,
              null,
              "other",
              null
            );
            this.displayTemplate = 4;
          } else if (chapterClicked.type == "youtube_video") {
            this.setUserActivityProgress(
              chapterClicked.id,
              null,
              "other",
              null
            );
            this.displayTemplate = 4;
          } else {
            this.setUserActivityProgress(
              chapterClicked.id,
              null,
              "other",
              null
            );
            this.displayTemplate = 2;
          }
        });
    },

    // Clicking one of the labels of a sub course, to display its data
    getSubCourseContent(idOfContent, contentOfLessonNav) {
      this.currentLessonLabel = contentOfLessonNav.label;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lesson_chapters_content?chapter_id=" +
            idOfContent,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.lessonContentData = response.data;
          this.displayTemplate = 3;
          this.clickedLessonID = idOfContent;
          this.getTheLessonNavigation();
        });
      // this.setUserActivityProgress(idOfContent, null, 'other', hasTask)
      // this.setUserActivityProgress(idOfContent, null, 'other')
    },

    /* Additional Navigation from within the lesson (next lesson and prev lesson)
      - Get clicked index of the lesson.
      - Get length of array and updated index to match
      - Lastly have a check if clicked index are 0 or at the end of the array
    */
    getTheLessonNavigation() {
      let arrayIndex = this.subCourseData.findIndex(
        (el) => el.id == this.clickedLessonID
      );
      let lengthOfArray = this.subCourseData.length;
      let arrayCheckIndex = arrayIndex + 1;
      if (arrayIndex == 0) {
        this.nextLessonLabel = this.subCourseData[arrayIndex + 1].label;
        this.nextLesson = this.subCourseData[arrayIndex + 1];
        this.lessonIndexStatus = "start";
      } else if (arrayCheckIndex == lengthOfArray) {
        this.previousLessonLabel = this.subCourseData[arrayIndex - 1].label;
        this.previousLesson = this.subCourseData[arrayIndex - 1];
        this.lessonIndexStatus = "end";
      } else {
        this.previousLesson = this.subCourseData[arrayIndex - 1];
        this.previousLessonLabel = this.subCourseData[arrayIndex - 1].label;
        this.nextLesson = this.subCourseData[arrayIndex + 1];
        this.nextLessonLabel = this.subCourseData[arrayIndex + 1].label;
        this.lessonIndexStatus = "ok";
      }
    },

    // Open an lesson image into a bigger one
    openLargerImageTemplate(imageData) {
      this.largerImageData = imageData;
      this.displayTemplate = 5;
    },

    // Return from larger image to lesson
    returnToLesson() {
      this.displayTemplate = 3;
      this.largerImageData = "";
    },

    // Making sure all v-models are cleaned
    resetFunction() {
      this.subCourseDataParent = "";
      this.subCourseData = "";
    },

    /* ===== Navigation ===== */

    // Go back to Root Course
    goBackToRootCourse() {
      this.displayTemplate = 1;
      this.resetFunction();
    },

    // Go back to the subCourse after getting into one of its lections.
    goBackToSubCourse() {
      this.displayTemplate = 2;
      this.lessonContentData = "";
    },

    goToPreviousLesson() {
      let idOfContent = this.previousLesson.id;
      this.currentLessonLabel = this.previousLesson.label;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lesson_chapters_content?chapter_id=" +
            idOfContent,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.lessonContentData = response.data;
          this.displayTemplate = 3;
          this.clickedLessonID = idOfContent;
          this.getTheLessonNavigation();
        });
    },

    goToNextLesson() {
      let idOfContent = this.nextLesson.id;
      this.currentLessonLabel = this.nextLesson.label;
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/lesson_chapters_content?chapter_id=" +
            idOfContent,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.lessonContentData = response.data;
          this.displayTemplate = 3;
          this.clickedLessonID = idOfContent;
          this.getTheLessonNavigation();
        });
    },

    /* ===== Tracking Functions ===== */

    // Get all user activity progress
    getUserActivityProgress(userActivityId) {
      this.$http
        .get(
          "https://app.followup.prios.no/api/courses/collection_activity_progress?courses_collection_activity_id=" +
            userActivityId,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.userActivityProgress = response.data;
        });
    },

    // Set user activity progress
    // setUserActivityProgress(collectionContentId,lessonChapterId,contentType,hasTask){
    setUserActivityProgress(
      collectionContentId,
      lessonChapterId,
      contentType,
      hasTask
    ) {
      if (this.userActivityId) {
        let findActivityProgress = this.userActivityProgress.find((i) =>
          lessonChapterId
            ? i.courses_collection_content_id == collectionContentId &&
              i.lesson_chapter_id == lessonChapterId
            : i.courses_collection_content_id == collectionContentId &&
              i.lesson_chapter_id == null
        );
        if (!findActivityProgress) {
          let newCreateObj = {
            courses_collection_activity_id: this.userActivityId,
            courses_collection_content_id: collectionContentId,
            lesson_chapter_id: lessonChapterId,
          };
          // if(contentType == "lesson_chapter" && !hasTask){
          if (contentType == "lesson_chapter") {
            this.$http
              .post(
                "https://app.followup.prios.no/api/courses/collection_activity_progress",
                newCreateObj,
                { headers: { Tempaccess: this.accessKey } }
              )
              .then(async (response) => {
                // newCreateObj.id = response.data.insertId
                this.userActivityProgress.push(newCreateObj);
                let checkContentLength = this.userActivityProgress.filter(
                  (i) => i.courses_collection_content_id == collectionContentId
                ).length;
                if (checkContentLength == this.subCourseData.length) {
                  let lessonDoneCreateObj = {
                    courses_collection_activity_id: this.userActivityId,
                    courses_collection_content_id: collectionContentId,
                    lesson_chapter_id: null,
                  };
                  let postLessonComplete = await this.$http.post(
                    "https://app.followup.prios.no/api/courses/collection_activity_progress",
                    lessonDoneCreateObj,
                    { headers: { Tempaccess: this.accessKey } }
                  );
                  this.userActivityProgress.push(lessonDoneCreateObj);
                }
              });
          } else if (!hasTask) {
            this.$http
              .post(
                "https://app.followup.prios.no/api/courses/collection_activity_progress",
                newCreateObj,
                { headers: { Tempaccess: this.accessKey } }
              )
              .then((response) => {
                this.userActivityProgress.push(newCreateObj);
              });
          } else {
            this.tempCurrentContentObj = {
              collectionContentId: collectionContentId,
              lessonChapterId: lessonChapterId,
              contentType: contentType,
              hasTask: false,
            };
          }
        }
      }
    },
    checkActivityProgress(collectionContentId, lessonChapterId) {
      let findActivityProgress = this.userActivityProgress.find((i) =>
        lessonChapterId
          ? i.courses_collection_content_id == collectionContentId &&
            i.lesson_chapter_id == lessonChapterId
          : i.courses_collection_content_id == collectionContentId &&
            i.lesson_chapter_id == null
      );
      if (findActivityProgress) {
        return true;
      } else {
        return false;
      }
    },
    setTaskContentDone() {
      if (this.tempCurrentContentObj.collectionContentId) {
        this.setUserActivityProgress(
          this.tempCurrentContentObj.collectionContentId,
          this.tempCurrentContentObj.lessonChapterId,
          this.tempCurrentContentObj.contentType,
          this.tempCurrentContentObj.hasTask
        );
        this.tempCurrentContentObj = {};
      }
    },
  },
  computed: {
    setProgressScore() {
      let getUserProgressLength = this.userActivityProgress.filter(
        (i) => i.courses_collection_content_id == this.subCourseDataParent.id
      ).length;
      if (getUserProgressLength > this.subCourseData.length) {
        return (this.percentageCompleted = 100);
      } else {
        let calculator =
          (100 * getUserProgressLength) / this.subCourseData.length;
        return (this.percentageCompleted = Math.round(calculator));
      }
    },
  },
  watch: {
    lessonContentData() {
      if (this.lessonContentData.length != 0) {
        let hasTask = false;
        if (this.lessonContentData.map((i) => i.type).includes("task")) {
          hasTask = true;
        }
        this.setUserActivityProgress(
          this.subCourseDataParent.id,
          this.clickedLessonID,
          "lesson_chapter",
          hasTask
        );
      }
    },
  },
};
</script>
