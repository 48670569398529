<template>
  <div class="pa-3" v-if="questionData.length != 0">
    <template v-if="currentTemplate == 1">
      <!-- <p>Lenght of questions: {{questionData.length}} </p>
    <p>Answered Questions:  </p>
    <p>Correct Answered Questions:  {{currentAttempts}} </p>
    <p>Wrongly Answered Questions:  {{incorrectAnswers}}</p>
    <p>Percentage of the assessment:   </p>
    <p>Name of module  {{ moduleName }} </p> -->
      <!-- <pre>{{questionProgress}}</pre> -->
      <v-stepper v-model="questionStepper" non-linear>
        <v-stepper-items>
          <v-stepper-content
            v-for="(item, index) in questionData"
            :step="index + 1"
            :key="index"
          >
            <v-card class="mb-7 mt-5 textColorList elevation-0">
              <v-layout row wrap>
                <!-- <v-flex xs6>
            <p class="pl-4">Nr: <span class="displayCircleStyle">{{questionStepper}}/{{questionData.length}}</span></p>
          </v-flex> -->
                <!-- <v-flex xs6 class="text-xs-right">
            <p>Nr: <span class="displayCircleStyle">{{currentAttempts}}</span></p>
          </v-flex> -->
              </v-layout>
              <div class="" v-if="item.type == 'text_multiple'">
                <p class="subheading">{{ item.question }}</p>
                <v-radio-group
                  v-model="selectedValue"
                  class="radioAlignClass ml-2"
                  @change="resetAnswerAttempt"
                >
                  <v-radio
                    :label="questionOption"
                    :value="questionOption"
                    v-for="(questionOption, optionIndex) in item.options"
                    :key="optionIndex"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="" v-if="item.type == 'text_sort'">
                <p class="subheading">{{ item.question }}</p>
                <draggable
                  v-model="item.options"
                  group="options"
                  class="mt-1 pt-1"
                  :sort="true"
                  ghostClass="ghost"
                  animation="200"
                  @change="resetAnswerAttempt"
                >
                  <transition-group type="transition">
                    <div
                      draggable
                      class="optiondrag pa-2 sortableQuestion"
                      v-for="(questionOption, optionIndex) in item.options"
                      :key="optionIndex"
                    >
                      <p>{{ optionIndex + 1 }}. {{ questionOption }}</p>
                    </div>
                  </transition-group>
                </draggable>
              </div>
              <div class="" v-if="item.type == 'text_fill'">
                <p class="subheading">{{ item.question }}</p>
                <v-text-field
                  @click="resetAnswerAttempt"
                  box
                  label="Your answer"
                  v-model="selectedValue"
                ></v-text-field>
              </div>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
        <template>
          <div class="ml-2 mr-2 mb-5">
            <v-layout row>
              <v-flex>
                <v-pagination
                  id="paginationItem"
                  circle
                  :color="setPagnationColors"
                  v-model="questionStepper"
                  :length="questionData.length"
                ></v-pagination>
              </v-flex>
              <v-flex class="text-xs-right">
                <v-btn
                  v-if="checkQuestionProgress == 'notAnswered'"
                  color="primary"
                  :disabled="
                    questionData[questionStepper - 1].type != 'text_sort' &&
                    !selectedValue
                  "
                  @click="checkAnswer()"
                  class="text-capitalize"
                >
                  Save answer
                </v-btn>
                <v-btn
                  v-else-if="checkQuestionProgress == 'correctAnswer'"
                  color="primary"
                  class="text-capitalize"
                >
                  <!-- Correct answer -->
                  Save Answer
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  :disabled="
                    questionData[questionStepper - 1].type != 'text_sort' &&
                    !selectedValue
                  "
                  class="text-capitalize"
                  @click="checkAnswer()"
                >
                  save Answer
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
        </template>
      </v-stepper>
    </template>

    <!-- Template 2 - SUCCESS! User scored 60% -->
    <template v-if="currentTemplate == 2">
      <p>Well done! you scored {{ this.percentageOfAssessment }} %</p>
      <p>Click here to get your certificate!</p>
      <v-btn @click="generateCertificate()">Generate PDF</v-btn>
      <p>
        Below are the questions that were incorrect, answer them correctly to
        further increase the % score!
      </p>
      <v-row class="ma-0 pa-0" v-if="this.percentageOfAssessment < 100">
        <v-card class="pa-3 pt-0" flat>
          <v-col
            class="ma-0 pa-0"
            cols="12"
            v-for="(question, questionIndex) in this.incorrectAnswers"
            :key="questionIndex"
          >
            <p>{{ questionIndex + 1 }} {{ question.question }}</p>
          </v-col>
        </v-card>
      </v-row>
      <v-btn @click="goToChapterOverview()">
        Click here if wanting to read through the module again
      </v-btn>
    </template>

    <!-- Template 3 - User scored below 60% -->
    <template v-if="currentTemplate == 3">
      <!-- <p>Template 3</p> -->
      <p>Unfortunately the assessment score were too low.</p>
      <p>
        It need to be 60% or more and you got {{ this.percentageOfAssessment }}%
      </p>
      <p>Luckily you are able to take the test multiple times!</p>
      <p>Below are the questions, which were incorrect.</p>
      <v-row class="ma-0 pa-0">
        <v-card class="pa-3 pt-0" flat>
          <v-col
            class="ma-0 pa-0"
            cols="12"
            v-for="(question, questionIndex) in this.incorrectAnswers"
            :key="questionIndex"
          >
            <p>{{ questionIndex + 1 }} {{ question.question }}</p>
          </v-col>
        </v-card>
      </v-row>
      <v-btn @click="goToChapterOverview()">
        Read through the module again
      </v-btn>
    </template>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  props: {
    gettingChapterContentTask: {
      type: String,
    },
    nameOfModule: {
      type: String,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      accessKey: process.env.VUE_APP_API_KEY,
      userID: "",
      CourseID: "",
      currentDate: "",
      // UsER Data
      userEmail: "",
      userFirstName: "",
      userLastName: "",
      moduleName: this.nameOfModule,

      formPdfData: null,
      questionData: [],
      questionProgress: [],
      questionStepper: 1,
      selectedValue: "",
      currentAttempts: 0,
      loadProgressDialog: true,

      totalQuestions: 0,
      correctAnswers: [],
      incorrectAnswers: [],
      percentageOfAssessment: 0,
      currentTemplate: 1,

      // Access Key to need to be placed in a .env file.
      // accessKey:window.btoa('bac436b32a36431bb437b9509b6d3495'),
      accessKey: "YmFjNDM2YjMyYTM2NDMxYmI0MzdiOTUwOWI2ZDM0OTU=",
    };
  },
  methods: {
    // get userID from localstorage
    getUserIdFromLocaleStorage() {
      let userId = localStorage.getItem("userID");
      this.userID = userId;
      console.log("userId", userId);
      console.log("userId", this.userID);
    },

    // get clickedCourseID from localstorage
    getCourseIdFromLocaleStorage() {
      let courseId = localStorage.getItem("clickedCourseID");
      this.CourseID = courseId;
      console.log("courseID", courseId);
      console.log("courseID", this.CourseID);
    },

    // Get User Data from Localstorage
    getUserDataFromLocaleStorage() {
      let userData = localStorage.getItem("user");
      userData = JSON.parse(userData);
      // console.log("userData", userData.fname);
      this.userEmail = userData.email;
      this.userFirstName = userData.fname;
      this.userLastName = userData.lname;
    },

    // get current date as new Date().toISOString().substr(0,10)
    getCurrentDate() {
      let currentDate = new Date().toISOString().substr(0, 10);
      this.currentDate = currentDate;
    },

    // Above 60% correct answers, click to generate PDF certificate.
    generateCertificate() {
      console.log("Generating certificate");
      let createObj = {
        emailOfUser: this.userEmail,
        firstNameOfUser: this.userFirstName,
        lastNameOfUser: this.userLastName,
        moduleName: this.moduleName,
      };

      this.formPdfData = createObj;
      // this.$http.get("smabProjectTemplate.html").then((getHtmlTemplate) => {
      this.$http.get("smabCertificateTemplate.html").then((getHtmlTemplate) => {
        let pdfData = {
          html: getHtmlTemplate.data,
          data: this.formPdfData,
        };
        this.$http({
          url: "https://pdfservice.prios.no/api/pdf_service",
          method: "post",
          responseType: "blob",
          data: pdfData,
        }).then((response) => {
          this.pdfProgressDialog = false;
          let pdfName = "smabCertificate.pdf";
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", pdfName);
          link.setAttribute("target", "_blank");
          link.click();
        });
      });

      this.postPersonWhoCompletedItToDB();
    },

    // Sending an userObject to the DB

    // postPersonWhoCompletedItToDB() {
    //   let userWhoCompletedIt = {
    //     user_id: this.userID,
    //     course_collection_id: this.CourseID,
    //     status: 1,
    //     start_date: this.currentDate,
    //     end_date: this.currentDate,
    //   };

    //   console.log(userWhoCompletedIt);
    //   this.$http
    //     .post(
    //       "https://app.followup.prios.no/api/courses/collection_activity",
    //       userWhoCompletedIt,
    //       { headers: { Tempaccess: this.accessKey } }
    //     )
    //     .then(() => {
    //       console.log("User has completed the module");
    //     });
    // },

    /* Calculating score of the assessment
      1. Get Total amount of questions
      2. Get Total amount of correct answered questions and same with incorrect answered questions.
      3. Get a check if all questions are answered.
      4. Calculate the percentage of the total vs completed questions.
      5. Redirect to said template based upon the answers. 
    */

    // #1. Get Total amount of questions
    getTotalAmountOfQuesitons() {
      this.totalQuestions = this.questionData.length;
      this.getCorrectAndIncorrectAnswers();
    },
    // #2. Run through questions, and split to correct and inncorrect answers array. && item !== item.user_answer != ""
    getCorrectAndIncorrectAnswers() {
      this.correctAnswers = this.questionProgress.filter(
        (item) => item.correct_answer === true
      );
      this.incorrectAnswers = this.questionProgress
        .filter((item) => item.correct_answer === false)
        .filter((item) => item.user_answer !== "");

      console.log("Correct Answers", this.correctAnswers);
      console.log("Incorrect Answers", this.incorrectAnswers);
      this.checkIfAllQuestionsHaveBeenAnswered();
    },

    // #3. Get a check if all questions are answered.
    checkIfAllQuestionsHaveBeenAnswered() {
      let amountOfAnsweredQuestions =
        this.correctAnswers.length + this.incorrectAnswers.length;
      console.log("Total Amount of questions", this.totalQuestions);
      console.log("Answered Questions", amountOfAnsweredQuestions);
      if (amountOfAnsweredQuestions == this.totalQuestions) {
        this.calculatePercentageOfAssessment();
      } else {
        this.questionStepper = this.questionStepper + 1;
      }
    },

    // #4. Calculate the percentage of the total vs completed questions.
    calculatePercentageOfAssessment() {
      this.percentageOfAssessment =
        (this.correctAnswers.length / this.totalQuestions) * 100;
      // console.log(this.percentageOfAssessment);
      this.getIncompleteQuestions();
      this.displayTemplateBasedOnResult(this.percentageOfAssessment);
    },

    // #5. Need to display the questions that were incorrectly answered.
    getIncompleteQuestions() {
      // Based on the IDs of the this.incorrectAnswers array, get the question data from questionData array with the same ID.
      let incorrectAnswers = this.incorrectAnswers;
      let questionData = this.questionData;
      let incorrectAnswersArray = [];
      incorrectAnswers.forEach((item, index) => {
        let findQuestion = questionData.find((i) => i.id == item.question_id);
        incorrectAnswersArray.push(findQuestion);
      });
      this.incorrectAnswers = incorrectAnswersArray;
    },

    // 6. Redirect to said template based upon the answers.
    displayTemplateBasedOnResult(percentageGotten) {
      console.log("Percentage completed are", percentageGotten);
      if (percentageGotten >= 60) {
        this.currentTemplate = 2;
      } else {
        this.currentTemplate = 3;
      }
    },

    goToChapterOverview() {
      this.$emit("goingBackToChapterOverview");
    },

    setPaginationStatusColors() {
      let selectAllItems = document.querySelectorAll(".v-pagination__item");
      selectAllItems.forEach((item, index) => {
        let findQuestion = this.questionData[index];
        let findQuestionProgress = this.questionProgress.find(
          (i) => i.question_id == findQuestion.id
        );
        if (
          findQuestionProgress.correct_answer == true &&
          findQuestionProgress.active == true
        ) {
          item.style.border = "2px solid grey";
        } else if (
          findQuestionProgress.correct_answer == false &&
          findQuestionProgress.active == true
        ) {
          item.style.border = "2px solid grey";
        }
      });
    },
    getTask() {
      this.questionData = [];
      // this.$axios.get(`/task_library/chapters?mode=getsinglechapterandquestions&chapter_id=${this.gettingChapterContentTask}`)
      // this.$axios.get(`/task_library/chapters?mode=getsinglechapterandquestions&chapter_id=${this.gettingChapterContentTask}` + this.gettingChapterContentTask,{headers:{Tempaccess:this.accessKey}}).then(response =>{
      this.$http
        .get(
          "https://app.followup.prios.no/api/task_library/chapters?mode=getsinglechapterandquestions&chapter_id=" +
            this.gettingChapterContentTask,
          { headers: { tempaccess: this.accessKey } }
        )
        .then((response) => {
          // console.log("Responsen av tasken", response);
          // console.log("sdfiuhjkahdajksh", this.gettingChapterContentTask);
          response.data.questions.forEach((item) => {
            if (item.options) {
              item.options = item.options.split("---");
              for (let i = item.options.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * i);
                let temp = item.options[i];
                item.options[i] = item.options[j];
                item.options[j] = temp;
              }
            }
            this.questionData.push(item);
            let questionProgressObj = {
              question_id: item.id,
              active: false,
              correct_answer: false,
              user_answer: "",
            };
            this.questionProgress.push(questionProgressObj);
          });
          this.questionStepper = 1;
        });
    },
    resetAnswerAttempt() {
      let findQuestion = this.questionData[this.questionStepper - 1];
      let findQuestionProgress = this.questionProgress.find(
        (i) => i.question_id == findQuestion.id
      );
      if (
        findQuestionProgress.correct_answer == false &&
        findQuestionProgress.active == true
      ) {
        findQuestionProgress.active = false;
      }
    },
    checkAnswer() {
      let findQuestion = this.questionData[this.questionStepper - 1];
      let findQuestionProgress = this.questionProgress.find(
        (i) => i.question_id == findQuestion.id
      );
      let correctValue = findQuestion.correct_answer.toLowerCase().trim();
      let userAnswer = this.selectedValue.toLowerCase().trim();
      if (findQuestion.type == "text_sort") {
        userAnswer = findQuestion.options.join("").toLowerCase();
      }
      if (correctValue != userAnswer) {
        this.currentAttempts++;
        findQuestionProgress.active = true;
        findQuestionProgress.user_answer = this.selectedValue;
        findQuestionProgress.correct_answer = false;
      } else {
        this.currentAttempts++;
        findQuestionProgress.active = true;
        findQuestionProgress.user_answer = this.selectedValue;
        findQuestionProgress.correct_answer = true;
      }
      this.setPaginationStatusColors();
      this.getTotalAmountOfQuesitons();
    },
  },
  mounted() {
    this.getTask();
    if (
      this.questionData.length != 0 &&
      this.questionData[this.questionStepper - 1].type == "text_sort"
    ) {
      this.selectedValue = "text_sort";
    }
    // this.getModuleInfo();

    this.currentTemplate = 1;
    this.getUserDataFromLocaleStorage();
    this.getUserIdFromLocaleStorage();
    this.getCourseIdFromLocaleStorage();
    this.getCurrentDate();
  },
  computed: {
    checkQuestionProgress() {
      let findQuestion = this.questionData[this.questionStepper - 1];
      let findQuestionProgress = this.questionProgress.find(
        (i) => i.question_id == findQuestion.id
      );
      if (
        findQuestionProgress.correct_answer == true &&
        findQuestionProgress.active == true
      ) {
        return "correctAnswer";
      } else if (
        findQuestionProgress.correct_answer == false &&
        findQuestionProgress.active == true
      ) {
        return "wrongAnswer";
      } else {
        return "notAnswered";
      }
    },
    setPagnationColors() {
      let findQuestion = this.questionData[this.questionStepper - 1];
      let findQuestionProgress = this.questionProgress.find(
        (i) => i.question_id == findQuestion.id
      );
      if (
        findQuestionProgress.correct_answer == true &&
        findQuestionProgress.active == true
      ) {
        return "grey";
      } else if (
        findQuestionProgress.correct_answer == false &&
        findQuestionProgress.active == true
      ) {
        return "grey";
      } else {
        return "";
      }
    },
  },
  watch: {
    questionStepper() {
      let findQuestionProgress =
        this.questionProgress[this.questionStepper - 1];
      this.selectedValue = findQuestionProgress.user_answer
        ? findQuestionProgress.user_answer
        : null;
      if (this.questionData[this.questionStepper - 1].type == "text_sort") {
        this.selectedValue = "text_sort";
      }
    },
  },
};
</script>

<style scoped>
.radioAlignClass {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
}

.sortableQuestion:hover {
  background-color: #546e7a;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.displayCircleStyle {
  height: 30px;
  width: 30px;
  background-color: #f6f7fa;
  color: #1a8096;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}

.textColorList {
  color: #616161;
}

.correctAnswerStyle {
  /* background-color:green !important; */
  background-color: grey !important;
}

.incorrectAnswerStyle {
  /* background-color:red; */
  background-color: grey;
}
</style>
