<template>
  <div>
    <ContactUs ref="OpenContactForm"></ContactUs>
    <EnrollDialog ref="openEnrollDialog"></EnrollDialog>
    <!-- Navbar -->
    <v-app-bar app style="height: 6em">
      <v-img
        class="mt-8 ml-10"
        src="img/smab/SMAB_New_logo.png"
        max-height="80"
        max-width="80"
        contain
      ></v-img>
      <!-- <pre>{{ userIsLoggedIn }}---</pre> -->
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleNavbar()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn icon @click="$refs.OpenContactForm.openContactDialog()">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Navbar -->
    <v-navigation-drawer v-model="navDrawer" app>
      <a href="/">
        <v-img
          class="mt-2 mb-2"
          src="img/smab/SMAB_New_logo.png"
          height="60"
          contain
        ></v-img>
      </a>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in pageNavigation"
          :key="index"
          :to="item.location"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item @click="$refs.OpenContactForm.openContactDialog()">
          <v-list-item-icon>
            <v-icon>mdi-email-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- fORUM -->
        <a
          class="ml-14"
          v-if="userIsLoggedIn"
          :href="
            'https://smab-forum-app-9vhg9.ondigitalocean.app/' + dataOfUser.guid
          "
          target="_blank"
        >
          <v-btn text>{{ $t("extrakeys.forum") }}</v-btn>
        </a>
        <div class="ml-14 mb-5">
          <v-btn
            v-if="userIsLoggedIn == false"
            text
            @click="$refs.openEnrollDialog.openDialog(true)"
          >
            <v-icon small>mdi-lock</v-icon>
            {{ $t("navbar.login") }}
          </v-btn>
          <v-btn v-else @click="userLogout()" text>
            <v-icon small>mdi-lock</v-icon>
            {{ $t("extrakeys.logout") }}
          </v-btn>
        </div>
        <!-- Language dropdown -->
        <div class="ml-10">
          <LanguageSelection></LanguageSelection>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ContactUs from "@/components/Global/ContactUs.vue";
import LanguageSelection from "@/components/TestAndDevelopment/SelectLocale.vue";
import EnrollDialog from "@/components/Login/enrollDialog.vue";

export default {
  components: {
    ContactUs,
    LanguageSelection,
    EnrollDialog,
  },
  data() {
    return {
      userIsLoggedIn: "",
      navDrawer: true,
      expand: true,
      dataOfUser: "",
      pageNavigation: [
        { name: this.$t("navbar.home"), location: "/", icon: "" },
        { name: this.$t("navbar.about"), location: "/about", icon: "" },
        { name: this.$t("navbar.partners"), location: "/partners", icon: "" },
        { name: this.$t("navbar.news"), location: "/news", icon: "" },
        // { name: "RSS", location: "/rss", icon: "" },
        // { name:this.$t('navbar.training modules'), location: "/trainingmodules", icon: "" },
        { name: "Mapping tool", location: "/mapping", icon: "" },
        { name: this.$t("navbar.resources"), location: "/resources", icon: "" },
      ],
    };
  },
  mounted() {
    this.checkIfLoggedIn();
  },
  methods: {
    // * Check if logged in, and getting user data
    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.dataOfUser = userData;
      if (userData) {
        this.userIsLoggedIn = true;
        // setTimeout(() => {  location.reload(); }, 100);
      } else {
        this.userIsLoggedIn = false;
      }
    },
    // * User Logout - to home page and reload page *
    userLogout() {
      localStorage.clear();
      this.$router.push("/");
      setTimeout(() => {
        location.reload();
      }, 100);
    },
    // Toggles the sidebar on and offs
    toggleNavbar() {
      if (this.navDrawer == true) {
        this.navDrawer = false;
      } else {
        this.navDrawer = true;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.fontTitle {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}
.fontSubTitle {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
}
.fontContent {
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
}
p {
  font-family: "Source Sans Pro", sans-serif;
}
</style>
