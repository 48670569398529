O
<template>
  <v-app-bar app class="navbarStyling" flat>
    <ContactForm ref="OpenContactForm"></ContactForm>
    <EnrollDialog ref="openEnrollDialog"></EnrollDialog>
    <v-spacer></v-spacer>
    <v-img
      src="@/assets/images/EUNEWLOGO.jpg"
      contain
      max-width="200"
      class="mt-10"
    ></v-img>
    <!-- Logo -->
    <a href="/" class="mt-5">
      <v-img
        class="mt-15 ml-10 mb-10"
        src="img/smab/SMAB_New_logo.png"
        max-height="100"
        max-width="100"
        contain
      ></v-img>
    </a>
    <v-spacer></v-spacer>

    <!-- Links -->
    <div class="mt-10">
      <a href="/">{{ $t("navbar.home") }}</a>
      <a href="/about">{{ $t("navbar.about") }}</a>
      <a href="/partners">{{ $t("navbar.partners") }}</a>
      <a href="/news">{{ $t("navbar.news") }}</a>
      <!-- <a href="/rss">{{ $t('navbar.rss') }}</a> -->
      <!-- <a href="/trainingmodules">{{ $t('navbar.training modules') }}</a> -->

      <a href="/mapping" class="mr-2">{{ $t("extrakeys.mappingTool") }}</a>
      <a href="/resources" class="mr-2">{{ $t("navbar.resources") }}</a>
      <span
        class="contactButton"
        @click="$refs.OpenContactForm.openContactDialog()"
        >{{ $t("navbar.contact") }}</span
      >
      <!-- fORUM -->
      <a
        v-if="userIsLoggedIn"
        :href="
          'https://smab-forum-app-9vhg9.ondigitalocean.app/' + dataOfUser.guid
        "
        target="_blank"
      >
        <v-btn text>{{ $t("extrakeys.forum") }}</v-btn>
      </a>
      <v-btn
        v-if="userIsLoggedIn == false"
        text
        @click="$refs.openEnrollDialog.openDialog(true)"
      >
        <v-icon small>mdi-lock</v-icon>
        {{ $t("navbar.login") }}
      </v-btn>
      <v-btn v-else @click="userLogout()" text>
        <v-icon small>mdi-lock</v-icon>
        {{ $t("extrakeys.logout") }}
      </v-btn>
    </div>

    <!-- Language dropdown -->
    <div class="mt-10">
      <LanguageSelection></LanguageSelection>
    </div>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import ContactForm from "@/components/Global/ContactUs.vue";
import LanguageSelection from "@/components/TestAndDevelopment/SelectLocale.vue";
import EnrollDialog from "@/components/Login/enrollDialog.vue";

export default {
  components: {
    ContactForm,
    LanguageSelection,
    EnrollDialog,
  },
  data() {
    return {
      userIsLoggedIn: "",
      dataOfUser: "",
      forumBaseUrl: "https://smab-forum-app-9vhg9.ondigitalocean.app/",
      newURL: "",
    };
  },
  mounted() {
    this.checkIfLoggedIn();
  },
  methods: {
    // * Check if logged in, and getting user data
    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.dataOfUser = userData;
      console.log("userData", userData);
      if (userData) {
        this.userIsLoggedIn = true;
        // setTimeout(() => {  location.reload(); }, 100);
      } else {
        this.userIsLoggedIn = false;
      }
    },
    // * User Logout - to home page and reload page *
    userLogout() {
      localStorage.clear();
      this.$router.push("/");
      setTimeout(() => {
        location.reload();
      }, 100);
    },
  },
  computed: {
    forumUrl() {
      this.newURL = this.forumBaseUrl + this.dataOfUser.guid;
      return this.forumBaseUrl + this.dataOfUser.guid;
    },
  },
};
</script>

<style scoped>
.navbarStyling {
  height: 6em !important;
  width: 90vw !important;
  margin: auto auto !important;
  background-color: #ffffff !important;
  border-bottom: 5px solid #004aad !important;
}
a {
  padding: 14px;
  color: #6a6a6a;
  text-decoration: none;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}
a:visited {
  color: #6a6a6a;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

/* Contact Button */
.contactButton {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  color: #6a6a6a;
  line-height: 1em;
  font-size: 16px;
}

.contactButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
