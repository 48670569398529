<template>
  <div class="homePageWidth">
    <v-row>
      <!-- ===== About Company ===== -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12">
            <p class="aboutTitle text-center" v-if="selectedLanguage == 'el'">
              {{ $t("about.about headliner") }}
            </p>
            <p v-else class="aboutTitle">{{ $t("about.about headliner") }}</p>
            <p
              class="aboutDescription text-center"
              v-if="selectedLanguage == 'el'"
            >
              <span> {{ $t("about.about description one") }} </span> <br />
              <span> {{ $t("about.about description two") }} </span> <br />
              <span> {{ $t("about.about description three") }} </span> <br />
              <span> {{ $t("about.about description four") }} </span> <br />
              <span> {{ $t("about.about description five") }} </span>
            </p>
            <p class="aboutDescription" v-else>
              <span> {{ $t("about.about description one") }} </span> <br />
              <span> {{ $t("about.about description two") }} </span> <br />
              <span> {{ $t("about.about description three") }} </span> <br />
              <span> {{ $t("about.about description four") }} </span> <br />
              <span> {{ $t("about.about description five") }} </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>

      <!-- ===== Aims and Results ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
            <v-row>
              <!-- Main Aim -->
              <v-col cols="12">
                <p
                  class="goalTitle text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.main aim headline") }}
                </p>
                <p class="goalTitle" v-else>
                  {{ $t("about.main aim headline") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.main aim description") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.main aim description") }}
                </p>
              </v-col>
              <!-- Aims -->
              <v-col cols="12" class="mb-15">
                <p
                  class="goalTitle text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims headline") }}
                </p>
                <p class="goalTitle" v-else>{{ $t("about.aims headline") }}</p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description one") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description one") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description two") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description two") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description three") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description three") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description four") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description four") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description five") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description five") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description six") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description six") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description seven") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description seven") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description eight") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description eight") }}
                </p>
                <p
                  class="goalDescription text-center"
                  v-if="selectedLanguage == 'el'"
                >
                  {{ $t("about.aims description nine") }}
                </p>
                <p class="goalDescription" v-else>
                  {{ $t("about.aims description nine") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <!-- ===== Results ===== -->
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
            <p class="goalTitle text-center" v-if="selectedLanguage == 'el'">
              {{ $t("about.results headliner") }}
            </p>
            <p class="goalTitle" v-else>{{ $t("about.results headliner") }}</p>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, index) in results" :key="index">
                <v-expansion-panel-header>
                  <p
                    class="goalDescription text-center"
                    style="font-weight: bold"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.title }}
                  </p>
                  <p class="goalDescription" style="font-weight: bold" v-else>
                    {{ item.title }}
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p
                    class="ma-0 pa-0 text-center"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.descriptionOne }}
                  </p>
                  <p class="ma-0 pa-0" v-else>{{ item.descriptionOne }}</p>
                  <p
                    class="ma-0 pa-0 text-center"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.descriptionTwo }}
                  </p>
                  <p class="ma-0 pa-0" v-else>{{ item.descriptionTwo }}</p>
                  <p
                    class="ma-0 pa-0 text-center"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.descriptionThree }}
                  </p>
                  <p class="ma-0 pa-0" v-else>{{ item.descriptionThree }}</p>
                  <p
                    class="ma-0 pa-0 text-center"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.descriptionFour }}
                  </p>
                  <p class="ma-0 pa-0" v-else>{{ item.descriptionFour }}</p>
                  <p
                    class="ma-0 pa-0 text-center"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.descriptionFive }}
                  </p>
                  <p class="ma-0 pa-0" v-else>{{ item.descriptionFive }}</p>
                  <p
                    class="ma-0 pa-0 text-center"
                    v-if="selectedLanguage == 'el'"
                  >
                    {{ item.descriptionSix }}
                  </p>
                  <p class="ma-0 pa-0" v-else>{{ item.descriptionSix }}</p>
                  <!-- <pre class="goalDescription" style="white-space: pre-wrap;">{{item.description}}</pre> -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LanguageTest from "@/components/TestAndDevelopment/LanguageTest.vue";
import SelectLocale from "@/components/TestAndDevelopment/SelectLocale.vue";
export default {
  name: "About",
  components: {
    LanguageTest,
    SelectLocale,
  },
  data() {
    return {
      selectedLanguage: "",
      keyBoxes: [
        { title: "Some key words" },
        { title: "To describe the project" },
        { title: "To make it more digestible" },
        { title: "To describe the project" },
        { title: "Some key words" },
      ],
      results: [
        {
          title: this.$t("about.results outputs one title"),
          descriptionOne: this.$t("about.results outputs one description one"),
          descriptionTwo: this.$t("about.results outputs one description two"),
          descriptionThree: this.$t(
            "about.results outputs one description three"
          ),
          descriptionFour: this.$t(
            "about.results outputs one description four"
          ),
        },
        {
          title: this.$t("about.results outputs two title"),
          descriptionOne: this.$t("about.results outputs two description one"),
          descriptionTwo: this.$t("about.results outputs two description two"),
          descriptionThree: this.$t(
            "about.results outputs two description three"
          ),
          descriptionFour: this.$t(
            "about.results outputs two description four"
          ),
          descriptionFour: this.$t(
            "about.results outputs two description five"
          ),
          descriptionFour: this.$t("about.results outputs two description six"),
        },
        {
          title: this.$t("about.results outputs three title"),
          descriptionOne: this.$t(
            "about.results outputs three description one"
          ),
          descriptionTwo: this.$t(
            "about.results outputs three description two"
          ),
          descriptionThree: this.$t(
            "about.results outputs three description three"
          ),
          descriptionFour: this.$t(
            "about.results outputs three description four"
          ),
        },
        {
          title: this.$t("about.results outputs four title"),
          descriptionOne: this.$t("about.results outputs four description one"),
          descriptionTwo: this.$t("about.results outputs four description two"),
          descriptionThree: this.$t(
            "about.results outputs four description three"
          ),
          descriptionFour: this.$t(
            "about.results outputs four description four"
          ),
        },
      ],
    };
  },
  methods: {
    getSelectedLanguage() {
      this.selectedLanguage = localStorage.getItem("language");
      console.log("Selected Language are", this.selectedLanguage);
    },
  },
  mounted() {
    this.getSelectedLanguage();
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

/* About Company */
.aboutTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6a6a6a;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: center;
  background-color: #e0f1ff;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.whomBackground {
  background-color: #f2f2f2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
</style>
