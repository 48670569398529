<template>
  <div class="homePageWidth">
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12" class="ma-0 pa-0 pt-16 mt-16">
        <v-card class="ma-0 pa-0 box" flat>
          <p class="ma-0 pa-0 partnerChapterTitle"> {{ $t('partners.partners headline') }} </p>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" v-for="(item, index) in projectPartners" :key="index">
        <v-card class="cardBorder ma-2 pa-7 px-2"  height="100%">
          <v-row>
            <v-col cols="12">
              <v-card height="90px" flat class="d-flex" :href="item.link" target="_blank">
                <v-img class="mt-5" :src="item.image" contain />
              </v-card>
            </v-col>
            <v-col class="pb-0 mb-0" cols="12">
              <p class="pl-3 partnerTitle">{{item.title}} 
                <span> 
                  <v-btn icon :href="item.link" target="_blank">
                    <v-icon color="green">mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </p>
            </v-col>
            <v-col v-if="selectedIndex !== index" cols="12" class="ma-0 pa-0 pl-3 pr-3">
              <p class="pl-3 pr-3 partnerDescriptionPre">{{item.descriptionOne}}</p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon v-if="selectedIndex !== index" @click="openingPartnerExpand(index)">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon v-else @click="closingPartnerExpand()">
              <v-icon large color="black">mdi-chevron-up</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="index === selectedIndex">
              <v-divider></v-divider>
              <v-card-text>
                <!-- <pre class="partnerDescriptionPost" style="white-space: pre-wrap;">{{item.description}}</pre> -->
                <p class="ma-0 pa-0">{{item.descriptionOne}}</p>
                <p class="ma-0 pa-0">{{item.descriptionTwo}}</p>
                <p class="ma-0 pa-0">{{item.descriptionThree}}</p>
                <p class="ma-0 pa-0">{{item.descriptionFour}}</p>
                <p class="ma-0 pa-0">{{item.descriptionFive}}</p>
                <p class="ma-0 pa-0">{{item.descriptionSix}}</p>
                <p class="ma-0 pa-0">{{item.descriptionSeven}}</p>
                <p class="ma-0 pa-0">{{item.descriptionEight}}</p>
                <p class="ma-0 pa-0">{{item.descriptionNine}}</p>
                <p class="ma-0 pa-0">{{item.descriptionTen}}</p>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: null,
      projectPartners: [
        { 
          title: this.$t('partners.partner kbt title'), 
          image: "img/smab/kbtfag.jfif", 
          link: "https://kbtkompetanse.no/",
          descriptionOne: this.$t('partners.partner kbt description one'),
          descriptionTwo: this.$t('partners.partner kbt description two'),
          descriptionThree: this.$t('partners.partner kbt description three'),
          descriptionFour: this.$t('partners.partner kbt description four'),
          descriptionFive: this.$t('partners.partner kbt description five'),
          descriptionSix: this.$t('partners.partner kbt description six'),
          descriptionSeven: this.$t('partners.partner kbt description seven'),
          descriptionEight: this.$t('partners.partner kbt description eight'),
        },
        { 
          title: this.$t('partners.partner prios title'), 
          image: "img/smab/prioslogo_PNG big_dark.png", 
          link: "https://www.prios.no/",
          descriptionOne: this.$t('partners.partner prios description one'),
          descriptionTwo: this.$t('partners.partner prios description two'),
          descriptionThree: this.$t('partners.partner prios description three'),
          descriptionFour: this.$t('partners.partner prios description four'),
          descriptionFive: this.$t('partners.partner prios description five'),
          descriptionSix: this.$t('partners.partner prios description six'),
        },
        { 
          title: this.$t('partners.partner startup migrants title'),
          image: "img/smab/startupmigrants_logo.png", 
          link: "http://startupmigrants.com/",
          descriptionOne: this.$t('partners.partner startup migrants description one'),
          descriptionTwo: this.$t('partners.partner startup migrants description two'),
          descriptionThree: this.$t('partners.partner startup migrants description three'),
          descriptionFour: this.$t('partners.partner startup migrants description four'),
          descriptionFive: this.$t('partners.partner startup migrants description five'),
        },
        { 
          title: this.$t('partners.partner clictic title'),
          image: "img/smab/Clictic.png", 
          link: "https://clictic.es/",
          descriptionOne: this.$t('partners.partner clictic description one'),
          descriptionTwo: this.$t('partners.partner clictic description two'),
          descriptionThree: this.$t('partners.partner clictic description three'),
          descriptionFour: this.$t('partners.partner clictic description four'),
        },
        { 
          title: this.$t('partners.partner innoved title'),
          image: "img/smab/innovedlogo.png", 
          link: "https://www.innoved.gr/",
          descriptionOne: this.$t('partners.partner innoved description one'),
          descriptionTwo: this.$t('partners.partner innoved description two'),
          descriptionThree: this.$t('partners.partner innoved description three'),
          descriptionFour: this.$t('partners.partner innoved description four'),
          descriptionFive: this.$t('partners.partner innoved description five'),
          descriptionSix: this.$t('partners.partner innoved description six'),
          descriptionSeven: this.$t('partners.partner innoved description seven'),
          descriptionEight: this.$t('partners.partner innoved description eight'),
        },
        { 
          title: this.$t('partners.partner meus title'),
          image: "img/smab/markeutskils_logo.png", 
          link: "https://meuskills.eu/",
          descriptionOne: this.$t('partners.partner meus description one'),
          descriptionTwo: this.$t('partners.partner meus description two'),
          descriptionThree: this.$t('partners.partner meus description three'),
          descriptionFour: this.$t('partners.partner meus description four'),
          descriptionFive: this.$t('partners.partner meus description five'),
          descriptionSix: this.$t('partners.partner meus description six'),
          descriptionSeven: this.$t('partners.partner meus description seven'),
        },
        { 
          title: this.$t('partners.partner euro lider title'),
          image: "img/smab/eurolider_logo.png", 
          link: "https://www.eurodesk.pl/",
          descriptionOne: this.$t('partners.partner euro lider description one'),
          descriptionTwo: this.$t('partners.partner euro lider description two'),
          descriptionThree: this.$t('partners.partner euro lider description three'),
          descriptionFour: this.$t('partners.partner euro lider description four'),
          descriptionFive: this.$t('partners.partner euro lider description five'),
          descriptionSix: this.$t('partners.partner euro lider description six'),
          descriptionSeven: this.$t('partners.partner euro lider description seven'),
          descriptionEight: this.$t('partners.partner euro lider description eight'),
          descriptionNine: this.$t('partners.partner euro lider description nine'),
          descriptionTen: this.$t('partners.partner euro lider description ten'),
        },
      ]
    }
  },
  methods: {
    openingPartnerExpand(index){
      this.selectedIndex = index;
    },

    closingPartnerExpand(){
      this.selectedIndex = null;
    },

  }
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
  margin-bottom: 50px;
}
/* Card CSS */
.box{
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right,  #004AAD 5%, transparent 5%);
  border-image-slice: 1;
}
.cardBorder{
  border: 3px #004AAD solid;
}


/* Text styling */
.partnerChapterTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.partnerTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #434343;
  letter-spacing: 0px;
  text-align: left;
}
.partnerDescriptionPre {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: left;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: left;
  color: #6A6A6A;
  opacity: 1;
  opacity: 1;
  letter-spacing: 0px;
}


</style>