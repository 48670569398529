<template>
  <div class="homePageWidth">
    <!-- <MappingToolMainComponent></MappingToolMainComponent> -->
    <!-- <v-btn @click="$refs.openMappingDialog.openingMappingForm(196)" large dark class="mt-15"> Start the Assessment Tool </v-btn>
    <MappingToolMainComponent ref="openMappingDialog" /> -->
    <TrainingModulePage></TrainingModulePage>
  </div>
</template>

<script>
import TrainingModulePage from "@/components/TrainingModule/TrainingModulePage.vue";
import MappingToolMainComponent from "@/components/MappingTool/MappingToolMainComponent.vue";
// import MappingToolMainComponent from "@/components/MappingTool/Test.vue"
export default {
  name: "Training-Modules",
  components: {
    TrainingModulePage,
    MappingToolMainComponent,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}
</style>
