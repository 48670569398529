<template>
  <div class="homePageWidth">
    <TrainingDialogTwo ref="openTrainingDialogTwo"></TrainingDialogTwo>
    <!-- <pre>{{allCourseCollection}}</pre>
      <p>---</p>
    -->
    <!-- <pre>{{filteredCourses}}</pre>  -->
    <template v-if="userIsLoggedIn == true">
      <template v-if="gotRecommendedCourse == true">
        <v-row>
          <v-col cols="12" class="my-15"></v-col>
          <v-col cols="12" class="my-15">
            <h1 class="text-center">
              {{ $t("extrakeys.recommendedCourses") }}
            </h1>
            <a href="/mapping"> <-- {{ $t("extrakeys.mappingTool") }}</a>
          </v-col>
          <v-col cols="12" class="mt-5 mb-15">
            <p>
              {{ $t("extrakeys.belowAreTheCourses") }}
            </p>
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
            v-for="(course, courseIndex) in filteredCourses"
            :key="courseIndex"
            class="pa-5"
          >
            <v-hover v-slot:default="{ hover }">
              <!-- <pre>{{course}}</pre> -->
              <v-card
                :elevation="hover ? 12 : 2"
                class="pa-5"
                height="100%"
                @click="$refs.openTrainingDialogTwo.openDialog(course)"
              >
                <p class="text-center title">{{ course.name }}</p>
                <v-img
                  :src="course.background_image"
                  class="mx-auto"
                  height="auto"
                  width="200px"
                  contain
                  :alt="course.name"
                ></v-img>
                <p>{{ course.description }}</p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12" class="my-15"></v-col>
          <v-col cols="12" class="my-15">
            <h1 class="text-center">
              {{ $t("extrakeys.recommendedCourses") }}
            </h1>
            <a href="/mapping"> <-- {{ $t("extrakeys.mappingTool") }}</a>
          </v-col>
          <v-col cols="12" class="mt-5 mb-15">
            <p>
              {{ $t("extrakeys.noRecommendedCoursesFound") }}
            </p>
          </v-col>
        </v-row>
      </template>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="12" class="my-15"></v-col>
        <v-col cols="12" class="my-15">
          <h1>{{ $t("extrakeys.pleaseLogIn") }}</h1>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import TrainingDialogTwo from "@/components/TrainingModule/TrainingModuleDialogThree.vue";
export default {
  components: {
    TrainingDialogTwo,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      courseCollections: [],
      allCourseCollection: [],
      gotRecommendedCourse: false,
      userData: [],
      userIsLoggedIn: false,
    };
  },
  mounted() {
    this.checkIfLoggedIn();
    this.checkIfUserGotAnyRecommendedCourses();
    this.getAllRecommendedCourses();
    this.getAllCourses();
  },
  methods: {
    getAllCourses() {
      this.allCourseCollection = [];
      this.$http
        .get(
          `https://app.followup.prios.no/api/courses/collection?tenant_id=106`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.allCourseCollection = response.data;
        });
    },

    checkIfUserGotAnyRecommendedCourses() {
      let recommendedCourses = JSON.parse(
        localStorage.getItem("selfAssessmentCourses")
      );
      if (recommendedCourses.length == 0) {
        this.gotRecommendedCourse = false;
      } else {
        this.gotRecommendedCourse = true;
      }
    },

    getAllRecommendedCourses() {
      let recommendedCourses = JSON.parse(
        localStorage.getItem("selfAssessmentCourses")
      );
      console.log("recommendedCourses", recommendedCourses);
      for (let index = 0; index < recommendedCourses.length; index++) {
        if (recommendedCourses[index].moduleName) {
          console.log("recommendedCourses[index]", recommendedCourses[index]);
          // this.courseCollections = recommendedCourses[index];
          this.courseCollections.push(recommendedCourses[index]);
          // console.log("asdqwdqwdqwd", recommendedCourses[index].moduleName)
        }
      }
    },

    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.userData = userData;
      if (userData) {
        this.userIsLoggedIn = true;
      } else {
        this.userIsLoggedIn = false;
      }
    },
  },

  computed: {
    filteredCourses() {
      // Create an array to store the filtered courses
      const filteredCourses = [];

      // Loop through courseCollections
      this.courseCollections.forEach((collection) => {
        console.log("collection", collection.matchingID);
        // Find the course in allCourseCollection with a matching ID
        const matchingCourse = this.allCourseCollection.find(
          (course) => course.id === collection.moduleId
        );

        console.log("matchingCourse", matchingCourse);

        // If a matching course is found, push it to the filteredCourses array
        if (matchingCourse) {
          filteredCourses.push(matchingCourse);
        }
      });

      // Return the filtered courses
      return filteredCourses;
    },
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}
</style>
